import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { FormeoEditor, FormeoRenderer } from 'formeo'
import 'formeo/dist/formeo.min.css';// from 'formeo';
import PropTypes from 'prop-types';

function ReactFormeoEditor(props) {
    const ref = useRef();

    useEffect(() => {
        const options = {
            editorContainer: ReactDOM.findDOMNode(ref.current),
            events: {
                onUpdate: (event) => {
                    try {
                        props.onChange(event.detail);

                        // remove required attribute on all elements so that we can actually save
                        setTimeout(() => {
                            // const fields = ref.current.querySelectorAll('[required]');
                            const fields = document.querySelectorAll('.formeo-editor [required]');
                            for(const field of fields) {
                                field.removeAttribute('required');
                                // console.log('removed attr')
                            }
                        }, 100);
                    } catch(e) {}
                },
            },
        };

        new FormeoEditor(options, props.formData || null);
    }, []);

    return (
        <div ref={ref} style={{width: '100%'}} />
    )
}
ReactFormeoEditor.propTypes = {
    formData: PropTypes.string,
    onChange: PropTypes.func,
};

function ReactFormeoRenderer(props) {
    const renderRef = useRef();
    const [formValues, setFormValues] = useState(props.values || {});
    let renderer = null;

    useEffect(() => {
        const options = {
            renderContainer: ReactDOM.findDOMNode(renderRef.current),
            debug: true,

            actions: {
                onRender: () => {
                    console.warn('afafa3');
                },
            },

            events: {
                onAdd: () => {
                    console.log('afafa1');
                },
                onUpdate: () => {
                    console.log('afafa2');
                },
                onRender: () => {
                    console.log('afafa3');
                },
            }
        };

        let formData = typeof props.formData !== 'string' ? JSON.stringify(props.formData) : props.formData;

        // fix null objects
        // todo: this probably needs adjustment
        if(formData) {
            formData = formData.replaceAll('null', '""');
        }

        formData = JSON.parse(formData);

        /*
        let requiredValues = {};
        for(const field of Object.values(formData.fields)) {
            if(field.attrs.hasOwnProperty('correct-answer')) {
                requiredValues[field.id] = field.attrs['correct-answer'];
            }
        }
        console.log(requiredValues);
        */
        // console.log(formData.fields);

        renderer = new FormeoRenderer(options);
        renderer.render(formData);

        for(const fieldId in formValues) {
            document.querySelector(`#f-${fieldId}`).value = formValues[fieldId]; // todo: sanitize
        }

        // console.log(formData);
        // try {
            for(const field of Object.values(formData.fields)) {
                // add required * to checkboxes and radio buttons
                try {
                    if(field.attrs.hasOwnProperty('required') && field.attrs.required && ['radio', 'checkbox'].indexOf(field.attrs.type) !== -1) {
                        let questionLabel = document.querySelector(`input[name="f-${field.id}"]`).closest('.f-field-group').previousSibling;
                        questionLabel.innerHTML += '<span class="text-error">*</span>';
                    }
                } catch(e) {}

                // add classes
                try {
                    if(field.attrs.hasOwnProperty('class')) {
                        let fieldGroup = document.querySelector(`input[name="f-${field.id}"]`).closest('.f-field-group');
                        fieldGroup.classList.add(field.attrs.class);

                        /*
                        for(const [rowId, row] of formData.stages) {
                            for(const [columnId, column] of row.children) {
                                for(const [cellId, cell] of column.children) {

                                }
                            }
                        }
                        */
                    }
                } catch(e) {}

                // handle custom conditional for media release opt out
                try {
                    // todo: make this more generic so we can specify the required key/value within the admin
                    if(field.attrs.hasOwnProperty('class') && field.attrs.class.indexOf('opt-out-listener') !== -1) {
                        let questions = document.querySelectorAll(`input[name="f-${field.id}"]`);
                        console.log('opt-out-listener', questions);
                        questions.forEach((question) => {
                            question.addEventListener('change', function(e) {
                                console.log(e.target);
                                if(e.target.value.indexOf('opt-out') !== -1 && e.target.checked) {
                                    document.querySelector('.hide-on-opt-out').closest('.formeo-row-wrap').style.display = 'none';
                                } else {
                                    document.querySelector('.hide-on-opt-out').closest('.formeo-row-wrap').style.display = 'block';
                                }
                            });
                        });
                    }
                } catch(e) {}
            }
        // } catch(e) {}
        // add <span class="text-error">*</span>

        /*
        const fields = renderRef.current.querySelectorAll('input, select, textarea');
        for(const field of fields) {
            field.addEventListener('change', (e) => {
                setFormValues({
                    ...formValues,
                    [field.name]: field.value,
                });
            });
        }
        */
    }, []);

    /*
    // this doesn't work
    useEffect(() => {
        const formData = typeof props.formData === 'string' ? JSON.parse(props.formData) : props.formData;
        console.log(formData);
        if(typeof renderer !== 'undefined') {
            console.log('FOOBAR');
            // renderer.render(formData);
        }
    }, [props?.formData]);
    */

    useEffect(() => {
        try {
            props.onChange(formValues);
        } catch(e) {}
    }, [formValues]);

    if(props.hasOwnProperty('onSubmit')) {
        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                // validate
                props.onSubmit(e);
            }}
            >
                <div ref={renderRef} style={{width: '100%'}} />
                {props.children}
            </form>
        );
    }

    return (
        <div ref={renderRef} style={{width: '100%'}} />
    )
}
ReactFormeoRenderer.propTypes = {
    formData: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export {
    ReactFormeoEditor,
    ReactFormeoRenderer,
};
