import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Accordion, Label, Radio, Select, Textarea } from "flowbite-react";
import { authenticatedApi } from "../Api";
import toast from "react-hot-toast";
import { useAuth } from "../AuthProvider";

export const AssessmentOverview = (props) => {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = () => {
        setIsLoading(true);

        let _answers = props.entry ? props.entry.entry : [];

        let _data = {
            standard_id: 'overview'
        };

        _answers.push(_data);

        let formEntry;
        if(props.entry) {
            formEntry = {
                entry: _answers,
            };
        } else {
            formEntry = {
                form_id: props.form.id,
                community_id: auth.currentCommunityId,
                status: 'draft',
                entry: _answers,

                form_schema: null,
                entry_schema: null,
            };
        }

        let saveFn = null;
        if(props.entry) {
            saveFn = new Promise((resolve, reject) => resolve({data: props.entry}));
        } else {
            saveFn = authenticatedApi.post('form-entries', formEntry);
        }
        saveFn.then(response => {
            let _entry = response.data;
            _entry.entry = typeof _entry.entry === 'string' ? JSON.parse(_entry.entry) : _entry.entry;
            // _entry.entry = JSON.parse(_entry.entry);
            props.setEntry(_entry);
            // props.setEntry(response.data);

            toast.success('Saved!');
            navigate(`/forms/${props.form.uuid}/baseline-requirements`);
        }).finally(() => {
            setIsLoading(false);
        });

        // todo: display error

        return true;
    };

    const ContinueButton = () => {
        let buttonClasses = [];
        /*if(!isValid) {
            buttonClasses.push('cursor-not-allowed opacity-75 !bg-gray-500');
        } else*/ if(isLoading) {
            buttonClasses.push('cursor-wait');
            buttonClasses.push('opacity-75');
        } else {
            buttonClasses.push('cursor-pointer');
        }

        return (<>
            <button
                // type="submit"
                onClick={onSubmit}
                className={`self-start inline-flex items-center mt-3 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 ${buttonClasses.join(' ')}`}
            >
                Continue
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
            {isLoading && <span className="inline-block ml-3 italic text-sm text-gray-400">Saving...</span>}
        </>);
    }

    const gradingScale = [
        [
            'Not being addressed',
            ['No work or planning in this area has been completed during this review cycle.'],
        ],
        [
            'Needs more effort',
            ['Some work or planning in this area has begun, but more effort is needed to meet the Standard.'],
        ],
        [
            'Satisfactory progress',
            ['Satisfactory work has been completed in this area. Minimum Standard is being met; work is fulfilling expectations and is acceptable, with room for improvement.'],
        ],
        [
            'Achieved success',
            ['Significant work has been completed in this area with meaningful results. Standard is being met with demonstrable impact on the program/community.'],
        ],
        [
            'Outstanding achievement',
            ['Substantial work has been completed in this area with extraordinary results above and beyond the Standard’s requirements. This score should be reserved for exemplary outcomes at the Great American Main Street Award winning level.'],
        ],
    ];

    return (<>
        <Accordion>
            <Accordion.Panel>
                <Accordion.Title className="bg-white text-black">Introduction</Accordion.Title>
                <Accordion.Content className="bg-white">
                    {props.form.description && (<div className="max-w-4xl rich-text-container" dangerouslySetInnerHTML={{__html: props.form.description }}></div>)}
                </Accordion.Content>
            </Accordion.Panel>

            <Accordion.Panel>
                <Accordion.Title className="bg-white text-black">How Grading Works</Accordion.Title>
                <Accordion.Content className="bg-white">
                    {props.form.how_grading_works && (<div className="max-w-4xl rich-text-container" dangerouslySetInnerHTML={{__html: props.form.how_grading_works }}></div>)}
                </Accordion.Content>
            </Accordion.Panel>

            {props.form.media.length > 0 ? (
            <Accordion.Panel>
                <Accordion.Title className="bg-white text-black">Sample Assessment</Accordion.Title>
                <Accordion.Content className="bg-white">

                {props.form.media.map(media =>
                    <a
                        key={media.id}
                        href={media.original_url} target="_blank"
                        className={`self-start inline-flex items-center mt-3 mb-3 mr-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300`}
                    >
                        View Sample Assessment
                        <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </a>
                )}
                </Accordion.Content>
            </Accordion.Panel>
            ) : <></>}

        </Accordion>

        <ContinueButton />
    </>);
}
