import { apiUrl, authenticatedApi } from "./Api";

export const buildCardsFromResponse = (responseData, additionalCards = {}) => {
    let generatedSections = [];
    for(const category in responseData) {
        let cards = [];

        for(const item of responseData[category]) {
            let card = {
                name: item.name,
                description: item.description ?? '',
                additional_info: item.additional_info ?? null,
            };

            if(card.description.indexOf('MCP16737F') !== -1) {
                card.copyButton = {
                    text: 'MCP16737F',
                    label: 'Copy Username',
                };
            }

            // if it has a fileable_id key, it's a file
            if(item.hasOwnProperty('fileable_id'))
            {
                card.button = {
                    link: item.url,
                    text: 'View',
                };
            }
            // if it has a schema, it's a form
            else if(item.hasOwnProperty('schema'))
            {
                // todo: handle completed
                card.button = {
                    linkTo: `/documents/forms/${item.uuid}`,
                    text: 'Fill Out',

                    // completed: true,
                    // text: 'Filled Out - Thank You!',
                };
            }

            cards.push(card);
        }

        if(additionalCards.hasOwnProperty(category)) {
            cards.push(...additionalCards[category]);
        }

        let section = {
            name: category,
            cards
        };

        generatedSections.push(section);
    }

    return generatedSections;
};

export const uploadFile = (params) => {
    let data = new FormData();
    for(const key in params.data) {
        if(key === 'file') continue;
        if(typeof params.data[key] === 'object') {
            data.append(key, JSON.stringify(params.data[key]));
        } else {
            data.append(key, params.data[key]);
        }
    }

    if(typeof params.data.file !== 'undefined' && params.data.file instanceof File) {
        data.append('file', params.data.file);
    }


    return authenticatedApi.post(`/files`, data);
};

export const round = (input) => {
    return Math.round(input * 100) / 100;
}

// https://stackoverflow.com/a/64489760
export const titleCase = (s) =>
  s.replace (/^[-_]*(.)/, (_, c) => c.toUpperCase())        // Initial char (after -/_)
   .replace (/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()); // First char after each -/_

export const boldifyName = (name, forceIfNoSplit = false) => {
    // For standards w/ colon
    let splitCharacter = ':';
    let split = name.split(splitCharacter+' ');

    // try periods if colon doesn't match
    if(split.length <= 1) {
        splitCharacter = '.';
        split = name.split(splitCharacter+' ');
    }

    let splitRejoined = split.slice(1).join(splitCharacter+' ');

    if(split.length > 1) {
        return `<b>${split[0]}${splitCharacter}</b> ${splitRejoined}`;
    }

    if(forceIfNoSplit) {
        return `<b>${name}</b>`;
    }

    return name;
}

export const injectStaticSections = (form, entry = {}) => {
    if(!entry || !entry.hasOwnProperty('entry') || !entry.entry) {
        entry = {entry: []};
    }

    const isOverviewComplete = entry.entry.filter(obj => obj.standard_id === 'overview').length;
    const isBaselineRequirementsComplete = entry.entry.filter(obj => obj.standard_id === 'baseline-requirements').length;
    const isAdditionalInfoComplete = entry.entry.filter(obj => obj.standard_id === 'additional-information').length;
    const isTotallyComplete = areAllIndicatorsComplete(form, entry);

    let overviewSection = {
        id: 'overview',
        name: 'Overview',
        status: isOverviewComplete ? 'completed' : 'default',
        progress: isOverviewComplete ? 100 : 0,
    };

    let baselineSection = {
        id: 'baseline-requirements',
        name: 'Baseline Requirements',
        status: isBaselineRequirementsComplete ? 'completed' : 'default',
        progress: isBaselineRequirementsComplete ? 100 : 0,
    };

    let additionalInfoSection = {
        id: 'additional-information',
        name: 'Additional Information',
        status: isAdditionalInfoComplete ? 'completed' : 'deafult',
        progress: isAdditionalInfoComplete ? 100 : 0,
    };

    let summarySection = {
        id: 'summary',
        name: 'Summary',
        status: isTotallyComplete ? 'completed' : 'deafult',
        progress: isTotallyComplete ? 100 : 0,
    };

    let injected = [
        overviewSection,
        baselineSection,
        ...form.standards.sort(sortBySequence),
    ];

    if(Array.isArray(form.additional_schema) && form.additional_schema.length) {
        injected.push(additionalInfoSection);
    }

    injected.push(summarySection);

    return injected;
};

export const getSimpleName = (name) => {
    if(typeof name === 'undefined' || name === null || name === false) {
        return false;
    }
    return name.split(': ')[0];
}

export const areAllIndicatorsComplete = (form, entry = {}) => {
    if(!entry || !entry.entry) {
        return false;
    }

    for(const standard of form.standards.sort(sortBySequence)) {
        for(const keyIndicator of standard.key_indicators.sort(sortBySequence)) {
            for(const indicator of keyIndicator.indicators.sort(sortBySequence)) {
                if(!entry.entry.find(obj => obj.indicator_id === indicator.id)) {
                    return false;
                }
            }
        }
    }

    return true;
};

export const getLinkForFirstUnfilledItem = (accessLevel, form, entry = {}) => {
    if(form === null) {
        return {nextText: 'Continue', nextLink: '/forms'};
    }

    let nextText = 'Continue';
    let nextLink = `/forms/${form.uuid}`;

    if(!entry || !entry.hasOwnProperty('entry') || !entry.entry) {
        nextText = 'Begin';
        // nextLink = `/forms/${form.uuid}/${form.standards[0].id}`;
        nextLink = `/forms/${form.uuid}/overview`;
        return {nextLink, nextText};
    }

    // Check if baseline requirements are complete
    if(!entry.entry.find(obj => obj.standard_id === 'baseline-requirements')) {
        nextText = 'Baseline Requirements';
        nextLink = `/forms/${form.uuid}/baseline-requirements`;
        return {nextLink, nextText};
    }

    for(const standard of form.standards.sort(sortBySequence)) {
        for(const keyIndicator of standard.key_indicators.sort(sortBySequence)) {
            for(const indicator of keyIndicator.indicators.sort(sortBySequence)) {
                const indicatorAnswer = entry.entry.find(obj => obj.indicator_id === indicator.id);

                // const indicatorAnswer = entry.entry.find(obj => obj.indicator_id === indicator.id);
                const acessLevelAnswered = indicatorAnswer ? indicatorAnswer[answerAccessKeys[accessLevel]] : false;

                if(!acessLevelAnswered) {
                    // if it's the first indicator in the first keyIndicator, go to standard instead
                    if(keyIndicator.sequence === 1) {
                        nextText = getSimpleName(standard.name);
                        nextLink = `/forms/${form.uuid}/${standard.id}`;
                    }
                    // if it's the first indicator in another keyIndicator, go to keyIndicator instead
                    else if(indicator.sequence === 1) {
                        nextText = keyIndicator.name;
                        nextLink = `/forms/${form.uuid}/${standard.id}/${keyIndicator.id}`;
                    } else {
                        nextText = indicator.name;
                        nextLink = `/forms/${form.uuid}/${standard.id}/${keyIndicator.id}/${indicator.id}`;
                    }

                    return {nextLink, nextText};
                }
            }
        }
    }

    /*
    let worksheetCompleted = entry.entry.filter(obj => obj.hasOwnProperty('worksheet')).length;
    if(worksheetCompleted) {
        nextText = 'View Entry';
        nextLink = `/forms/${form.uuid}/summary`;
    } else {
        nextText = 'Evaluation';
        nextLink = `/forms/${form.uuid}/worksheet`;
    }
    */

    // then check if we have additional information
    if(Array.isArray(form.additional_schema) && form.additional_schema.length) {
        nextText = 'Additional Information';
        nextLink = `/forms/${form.uuid}/additional-information`;
        return {nextLink, nextText};
    }

    // TODO: Check if status is in review or not
    nextText = 'View Entry';
    nextLink = `/forms/${form.uuid}/summary`;

    return {nextLink, nextText};
};

export function stateAbbrToName(acronym) {
    const states = {
      AZ: 'Arizona',
      AL: 'Alabama',
      AK: 'Alaska',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DC: 'District of Columbia',
      DE: 'Delaware',
      FL: 'Florida',
      GA: 'Georgia',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PA: 'Pennsylvania',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
      AS: 'American Samoa',
      GU: 'Guam',
      MP: 'Northern Mariana Islands',
      PR: 'Puerto Rico',
      VI: 'U.S. Virgin Islands',
      UM: 'U.S. Minor Outlying Islands',
    };

    return states[acronym] ?? acronym;
};

export function locationString(obj) {
    let toJoin = [];
    if(typeof obj.city === 'string' && obj.city.trim().length) {
      toJoin.push(obj.city);
    }
    if(typeof obj.state === 'string' && obj.state.trim().length) {
      toJoin.push(obj.state);
    }

    return toJoin.join(', ');
};

export const answerAccessKeys = {
    lp: 'lp_answer',
    cp: 'cp_answer',
    msa: 'msa_answer',
};

export const accessNames = {
    lp: 'Local Program',
    cp: 'Coordinating Program',
    msa: 'Main Street America',
};

export const formEntryStatuses = {
    draft: 'In Progress',
    'in-review': 'In Review',
    'needs-attention': 'Needs Attention',
    accepted: 'Accepted',
  };

export const designations = {
    accredited: 'Accredited',
    affiliate: 'Affiliate',
    none: 'N/A',
};

export const allowedFileExtensions = [
    // word
    'doc',
    'docx',
    'rtf',

    // excel
    'xls',
    'xlsx',

    // powerpoint
    'ppt',
    'pptx',

    // pdf, text
    'pdf',
    'txt',

    // images
    'jpg',
    'jpeg',
    'png',
];

export const getFileSize = (b) => {
    // https://stackoverflow.com/a/41402498
    var u = 0, s=1024;
    while (b >= s || -b >= s) {
        b /= s;
        u++;
    }
    return (u ? b.toFixed(1) + ' ' : b) + ' KMGTPEZY'[u] + 'B';
}


export const checkFileValidity = (event) => {
    const file = event.target.files[0];
    // console.log(file);

    // first check file size
    const fileSize = file.size;
    if(fileSize > (150 * 1000000)) { // ~150mib
    // if(fileSize > 1) {
        alert(`You cannot upload a file this big (${getFileSize(fileSize)}). Please limit your file to 50mb.`);
        event.target.value = ''; // reset
        return false;
    }

    // then check file extension
    const fileExtension = file.name.split('.').pop();

    if(allowedFileExtensions.indexOf(fileExtension.toLowerCase()) === -1) {
        alert(`The file type .${fileExtension} does not appear to be valid. Please choose a valid document or image.`);
        event.target.value = ''; // reset
        return false;
    }

    return true;
};

export function sortBySequence(a, b) {
    return a.sequence - b.sequence;
  }

export function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}
