import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Tooltip,
  } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export function BaselineRequirementNoAnswerModal(props) {
    const { isOpen, onOpen, onClose } = useDisclosure({isOpen: props.open, onOpen: props.onOpen, onClose: props.onClose});

    return (
    <>
        {/* <span onClick={onOpen} className="text-xs underline text-gray-600 cursor-pointer">Baseline Requirements</span> */}

        <Modal isOpen={isOpen} onClose={onClose} size='5xl'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Baseline Requirements</ModalHeader>
                <ModalCloseButton />

            <ModalBody>
                <p className="mb-3 text-sm font-normal">Please note that Local Programs that do not meet these baseline requirements are <span className='font-semibold'>not eligible for Accreditation</span> from Main Street America, though they may qualify for designation at the Affiliate level.</p>
            </ModalBody>
        </ModalContent>
        </Modal>
    </>
    )
}
