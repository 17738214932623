import * as React from "react";
import { unauthenticatedApi } from "./Api";

export const SettingsContext = React.createContext({
    settings: null,
    isLoading: true,

    isCurrentForm: (formId) => {},
    // signIn: (callback) => {},
    // signOut: (callback) => {},
  });

export function useSettings() {
    return React.useContext(SettingsContext);
}

export function SettingsProvider({ children }) {
    let [settings, setSettings] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
      if(isLoading && settings == null/* && checkToken != ''*/) {
        unauthenticatedApi.get('/settings/all').then(response => {
          setSettings(response.data);
        }).catch(e => {
          //
        }).finally(() => {
          setIsLoading(false);
        });
      }
    }, []);

    const isCurrentForm = (formId) => {
      if(!settings || !settings.active_form) {
        return false;
      }

      return formId === settings.active_form.id || formId === settings.active_form.uuid;
    };

    let value = {
      ...settings,
      isLoading,

      isCurrentForm,
    };

    return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
}
