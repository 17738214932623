import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import App from './App';
import { Toaster } from "react-hot-toast";

const container = document.getElementById('root');
const root = createRoot(container);

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  colors: {
    brand: {
      50: '#F5FAEF',
      100: '#EAF4DC',
      200: '#D2E8B5',
      300: '#BADD8D',
      400: '#A3D166',
      500: '#8BC53F',
      600: '#6D9D2F',
      700: '#4F7222',
      800: '#314615',
      900: '#131B08',
      950: '#040602'
    }
  },
  components: {
    Badge: {}
  },
})

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Toaster position="bottom-right" />
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
