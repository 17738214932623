import { faTriangleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function ErrorPage(props) {
    return (
        <div className="flex flex-col gap-1 align-center text-center text-gray-400">
            <FontAwesomeIcon size="5x" icon={faTriangleExclamation} />
            <p className="text-gray-600 text-xl font-semibold tracking-tight mt-6">Not Found</p>
            <p className="text-gray-600 tracking-tight">The page you were looking for could not be found. Please <Link to="/help" className="text-blue hover:text-blue-600">get help</Link> if you believe this is an error.</p>
        </div>
    );
}
