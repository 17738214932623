import { useState } from "react";
import {
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useAuth } from "../AuthProvider";
import PortalLoader from "../Components/PortalLoader";
import { Copyright } from "../Components/Copyright";
import { BeatLoader } from "react-spinners";
import { Alert, Button } from "@chakra-ui/react";

import logo from '../logo.svg';
import { unauthenticatedApi } from "../Api";

export default function ForgotPassword() {
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formError, setFormError] = useState('');
    const [formMessage, setFormMessage] = useState('');

    const [email, setEmail] = useState('');

    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    let from = location.state?.from?.pathname || "/";

    function handleSubmit(event) {
      event.preventDefault();

      setFormError('');
      setFormMessage('');
      setFormSubmitting(true);

      unauthenticatedApi.post('/password/email', {
        email
      }).then(() => {
        setFormMessage(`A password reset email has been sent to ${email}.`);
        //
      }).catch(e => {
        setFormError(e.response.data.message ?? 'An unknown error occurred while resetting your password.');
        //message
      }).finally(() => {
        setFormSubmitting(false);
      });
    }

    return (
      <div className="max-w-xl mx-auto">
        <img src={logo} className="App-logo mx-auto max-w-[50%]" alt="logo" />
        <h1 className="mt-12 text-green text-3xl text-center">Reset Password</h1>

        <div className="box rounded-lg shadow-lg mt-6 p-6 bg-white">

          {formError !== '' && (
            <Alert status='error' className='rounded-md mb-6' variant='top-accent'>{formError}</Alert>
          )}
          {formMessage !== '' && (
            <Alert status='info' className='rounded-md' variant='top-accent'>{formMessage}</Alert>
          )}

          {formSubmitting && (<PortalLoader text={'Sending...'} />)}
          {!formSubmitting && formMessage === '' && (
          <form onSubmit={handleSubmit}>
            <label className="block mb-6">
              <span className="block text-sm font-medium text-slate-700">Email</span>
              <input name="username" value={email} onChange={(e)=>setEmail(e.target.value)} type="text" className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500
              "/>
            </label>
            <Button
              isLoading={formSubmitting}
              colorScheme='brand'
              spinner={<BeatLoader size={8} color='white' />}
              type="submit"
              width="100%"
            >
              Send Reset Email
            </Button>
          </form>
          )}
        </div>

        <p className="mt-6 text-center text-sm">
            <Link to={'/login'} replace className="text-green">Back to Login</Link>
        </p>

        <Copyright/>
      </div>
    );
  }
