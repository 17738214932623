import { useDebounce, useLocalStorage } from "@uidotdev/usehooks";
import { Textarea } from "flowbite-react";
import TextareaAutosize from 'react-textarea-autosize';
import { forwardRef, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

// - autosize
// - autosave (requires a save_key_id)

export const NmsTextarea = forwardRef(function NmsTextarea(props, ref) {
    // const { autoSaveId = '_text', onChange, ...restProps } = props;
    // console.log(Object.keys(props));
    // use RHF ref if one is set, otherwise use our own
    // const _ref = useRef();
    const theRef = ref;// ?? _ref;

    /*
    if(autoSaveId === '_text') {
        console.warn('you must supply an autoSaveId');
    }
    */

    return (<>
        <TextareaAutosize
            minRows={3}
            ref={theRef}
            {...props}
            // these classes are copy/pasted from the flowbite Textarea
            className="
                block
                w-full
                rounded-lg
                border
                text-sm
                disabled:cursor-not-allowed
                disabled:opacity-50
                border-gray-300
                bg-gray-50
                text-gray-900
                focus:border-cyan-500
                focus:ring-cyan-500
                dark:border-gray-600
                dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 p-3
            "
        />
    </>)
})
