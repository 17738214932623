import { format, parse, parseISO } from "date-fns";
import { useState, useEffect } from "react";
import { authenticatedApi } from "../Api";
import PortalLoader from "./PortalLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-duotone-svg-icons";
import { Link, useNavigate } from "react-router-dom";


//users/me/notifications

const Notifications = (props) => {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isClearing, setIsClearing] = useState(false);

    const navigate = useNavigate();

    const isDetailScreen = props.detail ?? false;

    useEffect(() => {
        setIsLoading(true);

        authenticatedApi.get('/users/me/notifications').then(response => {
            // console.log(response);
            setNotifications(response.data);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const clearNotifications = () => {
        setIsClearing(true);

        authenticatedApi.post('/users/me/clear-notifications').then(response => {
            // console.log(response);
            setNotifications(response.data);
            navigate('/');

        }).finally(() => {
            setIsClearing(false);
        });
    }

    if(isLoading) {
        return (
            <div className="flex flex-col items-center block p-6 tracking-tight bg-white shadow">
                <PortalLoader text="Loading..." />
            </div>
        );
    }


    if(isDetailScreen) {
        return (<>
            <button
                type="button"
                onClick={clearNotifications}
                className={`self-start inline-flex items-center mt-0 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300`}
                disabled={isClearing}
            >
                Clear Notifications
                {/* <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg> */}
            </button>
            {isLoading && <span className="inline-block ml-3 italic text-sm text-gray-400">Saving...</span>}

            {notifications.map((notification, index) => (<>
                <div className="mb-6 text-md font-semibold tracking-tight bg-white shadow px-6 py-3 has-line" key={index}>
                    <p className="text-md font-semibold tracking-tight text-green-500">{notification.data.message}</p>
                    <p className="text-sm font-semibold tracking-tight text-green-500"><em className="text-gray-500">{format(parseISO(notification.created_at), 'MM/dd/yy')}</em></p>
                    {/* <p className="mb-3 text-sm font-normal tracking-tight" dangerouslySetInnerHTML={{__html: notification.data.message}}></p> */}
                </div>
            </>))}
        </>);
        //
    }

    return (
        <Link to={'/notifications'} className={`flex flex-col items-center block p-6 tracking-tight bg-white shadow ${notifications.length ? 'text-green-500' : 'text-gray-600'}`}>
            <p className="mb-2 text-md font-semibold tracking-tight text-green-500 text-center">{notifications.length ? (`${notifications.length} New ${notifications.length === 1 ? 'Notification' : 'Notifications'}`) : 'No New Notifications'}</p>
            <FontAwesomeIcon size="3x" icon={faBell} />
            {/* <h5 className="mb-2 text-4xl font-normal tracking-tight text-green-800 text-center">30 Days</h5> */}
        </Link>
    );
};

export default Notifications;
