import { format, parse, parseISO } from "date-fns";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { authenticatedApi } from "../Api";
import PortalLoader from "./PortalLoader";

const Announcements = (props) => {
    const [announcements, setAnnouncements] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const maxToDisplay = 3;
    const isDetailScreen = props.detail ?? false;

    useEffect(() => {
        setIsLoading(true);

        authenticatedApi.get('/announcements/current').then(response => {
            let data = response.data;

            // limit main announcements screen to the max
            if(!isDetailScreen) {
                // data.length = Math.min(data.length, maxToDisplay);
            }

            // if(isDetailScreen) {
            setAnnouncements(data);
            // } else {
                // setAnnouncements(response.data);
                //isDetailScreen
            // }
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const header = <>
        {props.includeHeader && (
            <p className="mb-6 text-md font-semibold tracking-tight text-blue-500 bg-white shadow px-6 py-3 has-line">Announcements</p>
        )}
    </>;

    if(isLoading) {
        return (<>
            <div className="flex flex-col block shadow px-6 py-3">
                <PortalLoader text="Loading..." />
            </div>
        </>);
    }

    return (<>
        {header}
        {announcements.length === 0 && <>
            <div className="mb-6 text-md font-semibold tracking-tight text-gray-500 bg-white shadow px-6 py-3 has-line">
                <em>There are no announcements to display at this time. Check back soon!</em>
            </div>
        </>}
        {announcements.map((announcement, index) => (isDetailScreen || (!isDetailScreen && index < maxToDisplay)) ? (
            <div className="mb-6 text-md font-semibold tracking-tight bg-white shadow px-6 py-3 has-line" key={index}>
                <p className="text-md font-semibold tracking-tight text-green-500">{announcement.title}</p>
                <p className="text-sm font-semibold tracking-tight text-green-500"><em className="text-gray-500">{format(parseISO(announcement.start_at || announcement.created_at), 'MM/dd/yy')}</em></p>
                <p className="mb-3 text-sm font-normal tracking-tight" dangerouslySetInnerHTML={{__html: announcement.message}}></p>
            </div>
        ) : null)}
            {announcements.length > maxToDisplay && !isDetailScreen && (
                <Link to={'/announcements'} className="self-start inline-flex items-center mt-0 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300">
                View All
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </Link>
            )}
    </>);
};

export default Announcements;
