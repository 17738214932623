import Notifications from "../Components/Notifications";

export default function AllNotifications() {
    return (<>
      <div className="mb-6">
        <h1 className="text-blue text-3xl">Notifications</h1>
      </div>

      <Notifications detail />
    </>);
}
