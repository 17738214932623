import { useAuth } from "../AuthProvider";
import { Label, Select, TextInput, Textarea } from "flowbite-react";
import { useForm } from "react-hook-form"
import { authenticatedApi } from "../Api";
import toast from "react-hot-toast";
import { useState } from "react";
import { titleCase } from "../helpers";
// import { ErrorMessage } from "@hookform/error-message"

export default function Profile() {
    let auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const { control, register, reset, handleSubmit, watch, formState: { errors, isValid, submitCount } } = useForm({
        mode: 'onTouched',
        defaultValues: {
          name: auth.user.name,
          email: auth.user.email,
          password: '',
          confirm_password: '',
        }
    });

    const onSubmit = (data) => {
      setIsLoading(true);

      let formEntry = {
        email: data.email,
        name: data.name,
      };

      if(data.password && data.confirm_password) {
        formEntry.password = data.password;
        formEntry.confirm_password = data.confirm_password;
      }

      authenticatedApi.put('users/'+auth.user.id, formEntry).then(response => {
          let origUser = auth.user;
          origUser.name = response.data.name;
          origUser.email = response.data.email;
          auth.setUser(origUser);

          reset({
            name: response.data.name,
            email: response.data.email,
            password: '',
            confirm_password: '',
          });
          /*
          reset({
            name: response.data.name,
            email: response.data.email,
          });
          */

          toast.success('Saved!');
          // navigate(nextLink);
      }).finally(() => {
          setIsLoading(false);
      });

      // todo: display error

      return true;
  };

  let buttonClasses = [];
  if(!isValid) {
      buttonClasses.push('cursor-not-allowed opacity-75 !bg-gray-500');
  } else if(isLoading) {
      buttonClasses.push('cursor-wait');
      buttonClasses.push('opacity-75');
  } else {
      buttonClasses.push('cursor-pointer');
  }

    return (<>
      <div className="mb-6">
        <h1 className="text-green text-3xl">My Account</h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`mb-3 rounded shadow bg-white max-w-3xl divide-y`}>
        <div className="flex flex-col gap-6 px-6 py-6">
          <div className="flex flex-row items-center gap-3">
            <div className="flex-initial basis-1/3">
              <Label htmlFor="name">Name</Label>
            </div>
            <div className="flex-auto">
              <TextInput
                {...register('name', {required: true})}
                id="name"
              />
            </div>
          </div>

          <div className="flex flex-row items-center gap-3">
            <div className="flex-initial basis-1/3">
              <Label htmlFor="email">Email</Label>
            </div>
            <div className="flex-auto">
              <TextInput
                {...register('email', {required: true})}
                id="email"
                type="email"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6 px-6 py-6">
          <div className="flex flex-row items-center gap-3">
            <div className="flex-initial basis-1/3">
              <Label htmlFor="password">New Password</Label>
            </div>
            <div className="flex-auto">
              <TextInput
                {...register('password')}
                id="password"
                type="password"
              />
            </div>
          </div>

          <div className="flex flex-row items-center gap-3">
            <div className="flex-initial basis-1/3">
              <Label htmlFor="confirm_password">Confirm Password</Label>
            </div>
            <div className="flex-auto">
              <TextInput
                {...register('confirm_password', {validate: (val) => {
                  // return true;
                  const pw = watch('password');
                  if(pw && val && (pw.length < 6 || val.length < 6)) {
                    return "Your password must be at least 6 characters long.";
                  }
                  if(/*pw && val && */pw != val) {
                    return "Your passwords do not match.";
                  }
                  return true;
                }})}
                id="confirm_password"
                type="password"
              />
            </div>
          </div>
          <em className="text-sm">Note: Leave these fields blank if you are not changing your password.</em>
        </div>

        <div className="flex flex-col gap-6 px-6 py-6">
        {/* <ErrorMessage errors={errors} name="singleErrorInput" /> */}
        {Object.entries(errors).map((error, index) => {
          const [errorKey, errorMessage] = error;
          let msg = errorMessage.message;
          if(!msg && errorMessage.type == 'required') {
            msg = `${titleCase(errorKey)} is a required field.`;
          }
          return <span className="text-red-700 font-bold" key={index}>{msg}</span>
        })}
          <button
                type="submit"
                // onClick={onClick}
                className={`self-start inline-flex items-center mt-0 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 ${buttonClasses.join(' ')}`}
                disabled={isLoading}
            >
                Save
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
            {isLoading && <span className="inline-block ml-3 italic text-sm text-gray-400">Saving...</span>}
        </div>
      </div>
      </form>

    </>);
}
