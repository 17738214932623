import { Link, useNavigate, useParams } from "react-router-dom";
import { accessNames, boldifyName, checkFileValidity, debounce, getSimpleName, round, sortBySequence } from "../helpers";
import { useEffect, useState } from "react";
import { FileInput, Label, Select, Textarea } from "flowbite-react";
import { useForm } from "react-hook-form"
import { authenticatedApi } from "../Api";
import toast from "react-hot-toast";
import { ScoringScaleModal } from "./ScoringScaleModal";
import { useAuth } from "../AuthProvider";
import { AccreditationContent } from "./AccreditationContent";
import ConfirmDeleteFile from "./ConfirmDeleteFile";

let controller = new AbortController();

export const Indicator = (props) => {
    const auth = useAuth();
    const accessLevel = auth.getCommunityAccessLevel();

    const navigate = useNavigate();
    const {
        uuid = null,
        standardId = null,
        keyIndicatorId = null,
        indicatorId = null
    } = useParams();

    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [scaleValues, setScaleValues] = useState([]);

    // autosaver for comments
    const autosaveFields = [
        'lp_notes',
        'cp_notes',
        'msa_notes',
    ];

    let defaultValues = {};
    let thisIndicatorEntry = null;
    if(props.entry) {
        thisIndicatorEntry = props.entry.entry.find(obj => obj.indicator_id === parseInt(indicatorId)) ?? null;
    }

    if(thisIndicatorEntry === null) {
        thisIndicatorEntry = {
            indicator_id: parseInt(indicatorId) ?? null,
            lp_answer: null,
            lp_notes: null,
            cp_answer: null,
            cp_notes: null,
            msa_answer: null,
            msa_notes: null,
        };
    }

    for(const autosaveField of autosaveFields) {
        let savedField = localStorage.getItem(`save_${indicatorId}_${autosaveField}`);
        if(savedField != null) {
            console.log(savedField);
            thisIndicatorEntry[autosaveField] = savedField;
        }
    }

    if(thisIndicatorEntry !== null) {
        defaultValues = thisIndicatorEntry;
    }

    const { control, register, reset, watch, handleSubmit, getValues, setValue, trigger, formState: { errors, isValid, submitCount } } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues,
    });

    const watched_lp_answer = watch('lp_answer');
    const watched_cp_answer = watch('cp_answer');
    const watched_msa_answer = watch('msa_answer');
    const watched_lp_notes = watch('lp_notes');
    const watched_cp_notes = watch('cp_notes');
    const watched_msa_notes = watch('msa_notes');

    // autosave
    useEffect(() => {
        const debouncedCb = debounce((formValue) => {
            // console.log(formValue);
            // console.log(dirtyFields);

            const data = getValues();

            let shouldAutosave = false;
            for(const autosaveField of autosaveFields) {
                if(
                    /*!!dirtyFields?.[autosaveField]
                    || */formValue?.[autosaveField] != defaultValues?.[autosaveField]
                    // && !!formValue?.[autosaveField]
                ) {
                    shouldAutosave = true;
                    if(formValue?.[autosaveField] != null) { // todo: remove this once we correclty set defaultvalues
                        localStorage.setItem(`save_${indicatorId}_${autosaveField}`, formValue?.[autosaveField]);
                    }
                }
            }

            if(shouldAutosave) {
                // console.log('WILL AUTOSAVE');
                // doSave(formValue);
                // reset(formValue); // fix default values
            } else {
                // console.log('WILL NOT AUTOSAVE');
            }
        }, 1000);

        const subscription = watch(debouncedCb);

        return () => subscription.unsubscribe();
    }, [watch]);

    const scoreNeedsExplanation = (type) => {
        // don't require CP/MSA notes for anything
        if(type === 'cp' || type === 'msa') {
            return false;
        }

        if(
            type === 'cp'
        )
        {
            if(accessLevel === 'msa' && watched_msa_notes) {
                return false;
            }

            if(
                watched_cp_answer
                && watched_lp_answer != watched_cp_answer
                && !watched_cp_notes
            ) {
                return "Please explain why your answer is different";
            }
        }

        if(
            type === 'msa'
            && watched_msa_answer
            && watched_lp_answer != watched_msa_answer
            && !watched_msa_notes
        ) {
            return "Please explain why your answer is different";
        }

        if(
            (
                type === 'lp'
                // && parseInt(watched_lp_answer) != 3
                && watched_lp_answer
                && !watched_lp_notes
            )
            /*
            ||
            (
                type === 'cp'
                // && parseInt(watched_lp_answer) != 3
                && watched_cp_answer
                && !watched_cp_notes
            )
            ||
            (
                type === 'msa'
                // && parseInt(watched_lp_answer) != 3
                && watched_msa_answer
                && !watched_msa_notes
            )
            */
        ) {
            return "Please elaborate your answer";
        }

        return false;
    }

    const CopyScore = (props) => {
        const doCopy = () => {
            setValue(`${props.to}_answer`, getValues(`${props.from}_answer`));
            trigger();
        };
        return (
            <span onClick={doCopy} className="text-xs underline text-gray-600 cursor-pointer">Copy From {props.from.toUpperCase()}</span>
        );
    }

    // Ensure grading scale is always sorted correctly
    useEffect(() => {
        if(!props.form || !props.form.grading_scale) {
            return;
        }

        const sortedScale = props.form.grading_scale.sort((a, b) => parseInt(a.score) - parseInt(b.score)).map(obj => obj.score);

        setScaleValues(sortedScale);
    }, [props.form]);


    // handle resetting form when indicator changes
    useEffect(() => {
        // build initial form state
        let thisIndicatorEntry = null;
        if(props.entry) {
            thisIndicatorEntry = props.entry.entry.find(obj => obj.indicator_id === parseInt(indicatorId)) ?? null;
        }

        if(thisIndicatorEntry === null) {
            thisIndicatorEntry = {
                indicator_id: parseInt(indicatorId) ?? null,
                lp_answer: null,
                lp_notes: null,
                cp_answer: null,
                cp_notes: null,
                msa_answer: null,
                msa_notes: null,
            };
        }

        for(const autosaveField of autosaveFields) {
            let savedField = localStorage.getItem(`save_${indicatorId}_${autosaveField}`);
            if(savedField != null) {
                thisIndicatorEntry[autosaveField] = savedField;
            }
        }

        reset(thisIndicatorEntry);
    }, [indicatorId]);


    if(!props.indicator) {
        return null;
    }

    const getNextLink = () => {
        let nextText = 'Continue';
        let nextLink = '';
        // first check if we have any more indicators in this key indicator

        // const indicatorIndex = props.keyIndicator.indicators.map(e => e.id).indexOf('stevie');
        let indicatorIndex = props.keyIndicator.indicators.sort(sortBySequence).findIndex(obj => obj.id === parseInt(indicatorId));//.indexOf(props.indicator);
        if(nextLink === '' && indicatorIndex > -1 && indicatorIndex+1 < props.keyIndicator.indicators.length) {
            let nextIndicator = props.keyIndicator.indicators.sort(sortBySequence)[indicatorIndex+1];
            nextText = nextIndicator.name;
            nextLink = `/forms/${props.form.uuid}/${props.standard.id}/${props.keyIndicator.id}/${nextIndicator.id}`;
        }

        // then check if we have any more key indicators in this standard
        let keyIndicatorIndex = props.standard.key_indicators.sort(sortBySequence).indexOf(props.keyIndicator);
        if(nextLink === '' && keyIndicatorIndex > -1 && keyIndicatorIndex+1 < props.standard.key_indicators.length) {
            let nextKeyIndicator = props.standard.key_indicators.sort(sortBySequence)[keyIndicatorIndex+1];
            nextText = nextKeyIndicator.name;
            nextLink = `/forms/${props.form.uuid}/${props.standard.id}/${nextKeyIndicator.id}`;
        }

        // then check if we have any more standards in this form
        let standardIndex = props.form.standards.sort(sortBySequence).indexOf(props.standard);
        if(nextLink === '' && standardIndex > -1 && standardIndex+1 < props.form.standards.length) {
            let nextStandard = props.form.standards.sort(sortBySequence)[standardIndex+1];
            nextText = getSimpleName(nextStandard.name);
            nextLink = `/forms/${props.form.uuid}/${nextStandard.id}`;
        }

        // then check if we have additional information
        if(nextLink === '' && Array.isArray(props.form.additional_schema) && props.form.additional_schema.length) {
            nextText = 'Additional Information';
            nextLink = `/forms/${props.form.uuid}/additional-information`;
        }

        // then go to review
        if(nextLink === '') {
            nextText = 'Summary';
            nextLink = `/forms/${props.form.uuid}/summary`;
        }

        return {nextLink, nextText};
    }
    /*
    const updateData = (data) => {
        // console.log(data);
    }
    const manuallySubmit = async () => {

    }
    */

    const onDeleteFile = (newMedia) => {
        const {media, ...prevEntry} = props.entry;
        prevEntry.media = newMedia;
        props.setEntry(prevEntry);
    };

    const onFileChange = (type, event) => {
        if(!checkFileValidity(event)) {
            return;
        }

        setIsUploadingFile(true);

        const formData = new FormData();
        formData.append('section', type);
        // console.log(type);
        formData.append(type, event.target.files[0]);

        authenticatedApi.post('form-entries/'+props.entry.id+'/media', formData).then(response => {
            if(response.data && response.data.media && response.data.media.length) {
                const {media, ...prevEntry} = props.entry;
                prevEntry.media = response.data.media;
                props.setEntry(prevEntry);
                event.target.value = ''; // reset form
            }
        }).finally(() => {
            setIsUploadingFile(false);
        });
    };

    const doSave = (data) => {
        // cancel any existing save
        try {
            controller.abort();
        } catch(e) {
            //
        }
        controller = new AbortController();

        let _answers = props.entry ? props.entry.entry : [];
        let _data = data;
        // fix strings
        for(const answerKey of ['lp_answer', 'cp_answer', 'msa_answer']) {
            if(_data.hasOwnProperty(answerKey) && _data.answerKey !== null) {
                _data[answerKey] = parseInt(_data[answerKey]);
            }
        }

        _data.indicator_id = parseInt(indicatorId);//props.indicator.id;

        // updating existing indicator answer
        let _index = _answers.findIndex(obj => obj.indicator_id === parseInt(indicatorId));
        if(_index !== -1 /*_entry.find(obj => obj.indicator_id === props.indicator.id)*/) {
            _answers[_index] = _data;
        }
        // create new indicator answer
        else
        {
            _answers.push(_data);
        }

        let formData = typeof _answers !== 'string' ? JSON.stringify(_answers) : _answers;

        let formEntry;
        if(props.entry) {
            formEntry = {
                entry: _answers,
            };
        } else {
            formEntry = {
                form_id: props.form.id,
                community_id: auth.currentCommunityId,
                status: 'draft',
                entry: _answers,

                form_schema: null,
                entry_schema: null,
            };
        }

        let saveFn = null;
        if(props.entry) {
            saveFn = authenticatedApi.put('form-entries/'+props.entry.id, formEntry, { signal: controller.signal });
        } else {
            saveFn = authenticatedApi.post('form-entries', formEntry, { signal: controller.signal });
        }
        return saveFn.then(response => {
            let _entry = response.data;
            _entry.entry = typeof _entry.entry === 'string' ? JSON.parse(_entry.entry) : _entry.entry;
            // _entry.entry = JSON.parse(_entry.entry);
            props.setEntry(_entry);

            for(const autosaveField of autosaveFields) {
                localStorage.removeItem(`save_${indicatorId}_${autosaveField}`);
            }
        });
    };

    const onSubmit = (data) => {
        // console.log(data);
        setIsLoading(true);
        const {nextText, nextLink} = getNextLink();

        let saveFn = doSave(data);

        saveFn.then(response => {
            toast.success('Saved!');
            navigate(nextLink);
            window.location.reload(); // todo: remove this after we figure out why things are being overridden
        }).finally(() => {
            setIsLoading(false);
        });

        // todo: display error

        return true;
    };


    const ContinueButton = () => {
        const {nextText, nextLink} = getNextLink();

        if(nextLink === '') {
            return null;
        }

        let buttonClasses = [];
        if(!isValid) {
            buttonClasses.push('cursor-not-allowed opacity-75 !bg-gray-500');
        } else if(isLoading) {
            buttonClasses.push('cursor-wait');
            buttonClasses.push('opacity-75');
        } else {
            buttonClasses.push('cursor-pointer');
        }

        return (<>
            <button
                type="submit"
                // onClick={onClick}
                className={`self-start inline-flex items-center mt-0 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 ${buttonClasses.join(' ')}`}
            >
                {nextText}
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
            {isLoading && <span className="inline-block ml-3 italic text-sm text-gray-400">Saving...</span>}
        </>);
    }

    // todo: make this database-related
    const mandatoryIndicatorText = '(Meeting this Indicator is a requirement and must be met to achieve Accreditation.)';
    const isMandatoryIndicator = props.indicator.description.indexOf('Meeting this Indicator') !== -1;
    //(Meeting this Indicator is a requirement and must be met to achieve Accreditation.)

    const tweakIndicatorDescription = (description) => {
        description = description.replace(mandatoryIndicatorText, '<span class="block text-sm font-bold text-red-700">'+mandatoryIndicatorText+'</span>');
        description = description.replaceAll(/\n/gi, '<br/><br/>')
        return description;
    };

    let answersToShow = ['lp'];
    if(accessLevel !== 'lp') {
        answersToShow.push('cp');
    }
    if(accessLevel === 'msa') {
        answersToShow.push('msa');
    }

    return (<>
        <h1 className="text-xl md:text-3xl font-semibold">{props.indicator.name}</h1>
        {/*  bg-white rounded shadow p-6 mb-6 */}
        <div className="max-w-4xl text-black" dangerouslySetInnerHTML={{__html: tweakIndicatorDescription(props.indicator.description) }}></div>

        {/* <div className="max-w-4xl text-gray-600 text-sm" dangerouslySetInnerHTML={{__html: props.indicator.examples.replaceAll(/\n/gi, '<br/>') }}></div> */}

        <AccreditationContent type={'list'} content={props.indicator.examples} />

        {/* TODO: Scoring Example Modal */}
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-6 flex gap-6">
            {answersToShow.map(type => (
                <div className="flex-auto max-w-lg" key={type}>
                    <p className="mb-3 text-md font-semibold tracking-tight text-blue-500">{accessNames[type]}</p>
                    <div className="bg-white shadow px-6 py-6">
                    <div className="flex gap-3 justify-between">
                        <Label htmlFor={`${type}_answer`} value="Score" />
                        {type === 'lp' && (
                            <ScoringScaleModal form={props.form} />
                        )}
                        {type !== 'lp' && (
                            <div className="flex gap-3">
                                <CopyScore from={'lp'} to={type} />
                                {type === 'msa' && (<CopyScore from={'cp'} to={type} />)}
                            </div>
                        )}
                        {/* <span className="text-xs underline text-gray-600 cursor-pointer">Scoring Scale</span> */}
                    </div>
                    <Select
                        id={`${type}_answer`}
                        {...register(`${type}_answer`, {
                            // required: true
                            validate: val => {
                                if(val) {
                                    return true;
                                }

                                if(accessLevel === 'msa' && type !== 'lp') {
                                    return true;
                                }

                                // don't require CP if MSA value is set
                                if(
                                    type === 'cp'
                                    && accessLevel === 'msa'
                                    && watched_msa_answer
                                ) {
                                    return true;
                                }

                                // don't require MSA if CP value is set
                                if(
                                    type === 'msa'
                                    && accessLevel === 'msa'
                                    && watched_cp_answer
                                ) {
                                    return true;
                                }

                                return 'Please provide an answer.';
                            }
                        })}
                    >
                        <option value="" disabled>Choose...</option>
                        {scaleValues.map(answer => <option value={answer} key={answer}>{answer}</option>)}
                    </Select>

                    <div className="py-3"/>

                    <Label htmlFor={`${type}_notes`} value={"Notes"} />
                    <Textarea
                        id={`${type}_notes`}
                        className="p-3"
                        {...register(`${type}_notes`, {
                            validate: val => {
                                if(val) {
                                    return true;
                                }

                                // always force LP to provide note //if score is NOT a 3
                                const lp_answer = watch('lp_answer');

                                if(type === 'lp') {
                                    // if(parseInt(lp_answer) != 3) {
                                        // if(!val) {
                                            return "Please elaborate your answer";
                                        // }
                                    // }

                                    // if LP, don't validate further
                                    // return true;
                                }

                                if(accessLevel === 'msa' && type !== 'lp') {
                                    return true;
                                }

                                // don't require CP if MSA value is set
                                if(
                                    type === 'cp'
                                    && accessLevel === 'msa'
                                    && watched_msa_answer
                                ) {
                                    return true;
                                }

                                // for CP / MSA, require explanation when answer is different
                                const this_answer = watch(`${type}_answer`);
                                if(parseInt(lp_answer) != parseInt(this_answer)) {
                                    // if(!val) {
                                        return "Please explain why your answer is different";
                                    // }
                                }

                                return "Please elaborate your answer";
                                // return true;
                            }
                        })}
                    ></Textarea>

                    {scoreNeedsExplanation(type) && (<em className="block mt-1 text-sm">{scoreNeedsExplanation(type)}</em>)}

                    {type === 'lp' && props.indicator.has_file_upload == true && (<>
                        <div className="py-3"/>

                        <Label htmlFor={`indicator_${props.indicator.id}`} value={"File"} />
                        <FileInput
                            // helperText="Documentation is required for this indicator."
                            id={`indicator_${props.indicator.id}`}
                            size={'sm'}
                            onChange={e => onFileChange(`indicator_${props.indicator.id}`, e)}
                            helperText={isUploadingFile ? 'Uploading...' : 'Files must be no larger than 50mb'}
                            disabled={isUploadingFile}
                        />

                        {!!props.entry && props.entry.media.filter(media => media.collection_name == `indicator_${props.indicator.id}`).length > 0 && (
                            <table className="table-auto w-full rounded overflow-hidden bg-white mt-6">
                                <tbody>
                                {props.entry.media.filter(media => media.collection_name == `indicator_${props.indicator.id}`).map((media, mediaIndex) => (
                                    <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-3 py-1.5 ${mediaIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`} key={mediaIndex}>
                                        <td className="px-3 py-1.5"><a href={media.original_url} target="_blank">{media.file_name}</a></td>
                                        <td className="px-3 py-1.5 text-right"><ConfirmDeleteFile media={media} onDelete={onDeleteFile} /></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}
                    </>)}
                    </div>
                </div>
            ))}
        </div>
        <ContinueButton />
        </form>
    </>);
}
