import {
    Button,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
// import { FormeoEditor, FormeoRenderer } from 'formeo'
// import 'formeo/dist/formeo.min.css';// from 'formeo';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { authenticatedApi } from '../../Api';
import { useAuth } from '../../AuthProvider';
import PortalLoader from '../../Components/PortalLoader';
import { ReactFormeoEditor, ReactFormeoRenderer } from './Formeo';
import './FormFill.scss';
import { Breadcrumb, Dropdown } from 'flowbite-react';
import { boldifyName, getSimpleName, injectStaticSections, round } from '../../helpers';
import { FormStandards } from '../../Components/FormStandards';
import { StandardKeyIndicators } from '../../Components/StandardKeyIndicators';
import { FormBreadcrumbs } from '../../Components/FormBreadcrumbs';
import { KeyIndicatorIndicators } from '../../Components/KeyIndicatorIndicators';
import { Indicator } from '../../Components/Indicator';
import { AssessmentBaselineRequirements } from '../../Components/AssessmentBaselineRequirements';
import { AssessmentOverview } from '../../Components/AssessmentOverview';
import { AssessmentSummmary } from '../../Components/AssessmentSummary';
import CommunityPicker from '../../Components/CommunityPicker';
import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { AssessmentAdditionalInformation } from '../../Components/AssessmentAdditionalInformation';
import { useSettings } from '../../SettingsProvider';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

export const FormFill = () => {
    const {
        uuid = null,
        standardId = null,
        keyIndicatorId = null,
        indicatorId = null
    } = useParams();
    // console.log(useParams());

    const navigate = useNavigate();
    const location = useLocation();
    // console.log(location);

    let auth = useAuth();
    let settings = useSettings();

    const [form, setForm] = useState(null);
    const [formSchema, setFormSchema] = useState('');

    const [entry, setEntry] = useState(null);
    // TODO: saveEntry helper fn?

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [incorrectAnswers, setIncorrectAnswers] = useState([]);

    const [isStaticSection, setIsStaticSection] = useState(false);
    const [currentStandard, setCurrentStandard] = useState(null);
    const [currentKeyIndicator, setCurrentKeyIndicator] = useState(null);
    const [currentIndicator, setCurrentIndicator] = useState(null);

    useEffect(() => {
        setIsLoading(true);

        //let getForm = authenticatedApi.get(`forms/${uuid}`).then(response => {

        let endpoint = `forms/${uuid}`;//'forms/current';
        if(auth.currentCommunity && auth.currentCommunity.coordinating_program_id) {
            endpoint += '?coordinating_program_id='+auth.currentCommunity.coordinating_program_id;
        }
        let getForm = authenticatedApi.get(endpoint).then(response => {
            let schema = response.data.additional_schema;
            let formData = typeof schema !== 'string' ? JSON.stringify(schema) : schema;

            // fix null objects
            // todo: this probably needs adjustment
            // formData = formData.replaceAll('null', '""');

            setForm(response.data);
            // setFormSchema(formData);
        });

        let getFormEntry = authenticatedApi.get(`form-entries/mine?filter_mode=and&community_id=${auth.currentCommunityId}&form.uuid=${uuid}`).then(response => {
            if(response.data.data.length) {
                let _entry = response.data.data[0];
                _entry.entry = JSON.parse(_entry.entry);
                delete _entry.form; // clean it up a bit
                // console.log(_entry.entry);
                setEntry(_entry);
            } else {
                setEntry(null);
            }
        });

        Promise.all([getForm, getFormEntry]).finally(() => {
            setIsLoading(false); // todo: move this into a catch statement
        });

    }, [uuid, auth.currentCommunityId]);

    useEffect(() => {
        if(isLoading) {
            return;
        }

        let newStaticSection = false;
        let newStandard = null;
        let newKeyIndicator = null;
        let newIndicator = null;

        // console.log('formcheck', form, form?.standards, standardId);

        if(
            form !== null
            && form.hasOwnProperty('standards')
            && standardId != null
        ) {
            newStandard = form.standards.find(obj => obj.id === parseInt(standardId)) ?? null;// objectById(form.standards, standardId);
        }

        if(newStandard != null) {
            newKeyIndicator = newStandard.key_indicators.find(obj => obj.id === parseInt(keyIndicatorId)) ?? null;// objectById(newStandard.key_indicators, keyIndicatorId);
        }

        if(newKeyIndicator != null) {
            newIndicator = newKeyIndicator.indicators.find(obj => obj.id === parseInt(indicatorId)) ?? null;// objectById(newKeyIndicator.indicators, indicatorId);
        }

        if(form !== null) {
            const standards = injectStaticSections(form);
            for(const standard of standards) {
                if(
                    typeof standard.id === 'string'
                    && location.pathname.indexOf(`/${standard.id}`) !== -1
                ) {
                    newStaticSection = true;
                    break;
                }
            }
        }

        setIsStaticSection(newStaticSection);
        setCurrentStandard(newStandard);
        setCurrentKeyIndicator(newKeyIndicator);
        setCurrentIndicator(newIndicator);

        // console.log('newcheck', newStandard?.id ?? null, newKeyIndicator?.id ?? null, newIndicator?.id ?? null);
        // console.log();
    }, [
        uuid,
        auth.currentCommunityId,
        standardId,
        keyIndicatorId,
        indicatorId,
        isLoading,
        location,
    ]);

    if(isLoading || form === null) {
        return <PortalLoader text="Loading..." />;
    }

    if(!auth.currentCommunity) {
        return (<>
            <div className="mb-6">
                <h1 className="text-green text-3xl">{form.name}</h1>
                <p className="mb-3 text-sm font-normal tracking-tight">To proceed, please choose a community.</p>
            </div>
            <div className="max-w-4xl">
                <CommunityPicker/>
            </div>
        </>);
    }

    // todo: determine user's permissions (i.e. submitted for user shouldn't see it, but coordinator / national should)

    // if is submitted, display success message
    if(isSubmitted !== false) {
        return (<>
            <div className="mb-6">
                <h1 className="text-green text-3xl">{form.name}</h1>
            </div>
            <div className="max-w-4xl">
                {!isError && (
                <Alert status='success' style={{width: 'auto', display: 'inline-flex'}}>
                    <AlertIcon />
                    {isSubmitted === true ? 'The form was successfully submitted.' : isSubmitted}
                </Alert>
                )}
                {!isError && Object.values(incorrectAnswers).length > 0 && (
                    <Box mt={4}>
                        <Alert status='warning' style={{width: 'auto', display: 'inline-flex'}}>
                            <AlertIcon />
                            <div>
                                <p>The following answers were incorrect:</p>
                                <ul className='ml-6 list-disc'>
                                    {Object.values(incorrectAnswers).map((validationError, index) => (
                                        <li key={index} dangerouslySetInnerHTML={{__html: validationError}}></li>
                                    ))}
                                </ul>
                            </div>
                        </Alert>
                    </Box>
                )}

                {isError && (
                    <Alert status='error' style={{width: 'auto', display: 'inline-flex'}}>
                        <AlertIcon />
                        {isError === true ? 'There was an error submitting the form.' : isError}
                    </Alert>
                )}
            </div>
        </>);
    }

    // redirect to form summary if local program tries to view it while it's under review
    if(
        entry
        && (
            ((entry.status === 'in-review' || entry.status === 'accepted')
            && auth.getCommunityAccessLevel() === 'lp')
            ||
            !settings.isCurrentForm(entry?.form_id)
        )
        && !(isStaticSection === true && standardId === 'summary')
    ) {
        navigate(`/forms/${form.uuid}/summary`);
        return;
    }
    // isStaticSection === true && standardId === 'summary' &&

    /*
    // todo: determine if user is local or not
    if(entry && entry.status === 'in-review' && auth.getCommunityAccessLevel() === 'lp') {
        return (
            <div className="mb-6">
                <h1 className="text-green text-3xl">{form.name}</h1>
                <div className="max-w-4xl text-black">Your assessment is currently in review. If you accidentally submitted your assessment, please reach out immediately.</div>
            </div>
        );
    }
    */

    // if error, display it with the form

    // const [quizSchema, setQuizSchema] = useState([]);


    let endAt = parseISO(/*settings.active_*/form.end_at);
    let endDate = format(endAt, 'MM/dd/yy');
    let daysRemaining = differenceInCalendarDays(endAt, new Date());

    return (<>
        <div className="mb-6 flex flex-col lg:flex-row justify-between">
            <div className='flex-auto'>
                <h1 className="text-green text-3xl">{form.name}</h1>
                <FormBreadcrumbs
                    entry={entry}
                    form={form}
                    standard={currentStandard}
                    keyIndicator={currentKeyIndicator}
                    indicator={currentIndicator}
                />
            </div>

            <div className='flex-initial lg:text-right'>
                <em>{auth.currentCommunity.name}</em>
                <br/>
                <p className={`text-sm ${daysRemaining <= 30 ? 'text-red-700 font-bold' : null}`}>
                {settings.isCurrentForm(entry?.form_id) && (!entry || entry.status !== 'accepted') && (<>
                    {daysRemaining >= 0 && (<><em>Due by {endDate}</em><br/><b>{`${daysRemaining} ${daysRemaining === 1 ? 'day' : 'days'}`}</b> remaining to complete</>)}
                    {daysRemaining < 0 && (<><b>FORM PAST DUE</b></>)}
                </>)}
                </p>
            </div>


        </div>

        {/* This is used to display the standard picker (when on the root form) */}
        {isStaticSection === false && currentStandard === null && (
            <FormStandards
                form={form}
                entry={entry}
            />
        )}

        {/* this is for displaying the overview section */}
        {isStaticSection === true && standardId === 'overview' && (
            <AssessmentOverview
                form={form}
                entry={entry}
                setEntry={setEntry}
            />
        )}

        {/* this is for displaying the baseline requirements */}
        {isStaticSection === true && standardId === 'baseline-requirements' && (
            <AssessmentBaselineRequirements
                form={form}
                entry={entry}
                setEntry={setEntry}
            />
        )}

        {/* this is used to display the key indicator picker (when on a standard) */}
        {currentStandard !== null && currentKeyIndicator === null && (
            <StandardKeyIndicators
                form={form}
                entry={entry}
                standard={currentStandard}
            />
        )}

        {/* this is used to display the key indicator picker (when on a standard) */}
        {currentKeyIndicator !== null && currentIndicator === null && (
            <KeyIndicatorIndicators
                form={form}
                entry={entry}
                standard={currentStandard}
                keyIndicator={currentKeyIndicator}
            />
        )}

        {/* this is used to display the actual indicator */}
        {currentIndicator !== null && (
            <Indicator
                form={form}
                entry={entry}
                setEntry={setEntry}
                standard={currentStandard}
                keyIndicator={currentKeyIndicator}
                indicator={currentIndicator}
            />
        )}

        {/* this is for displaying additonal questions from the coordinating program */}
        {isStaticSection === true && standardId === 'additional-information' && (
            <AssessmentAdditionalInformation
                form={form}
                entry={entry}
                setEntry={setEntry}
            />
        )}

        {/* this is used for displaying the form summary */}
        {isStaticSection === true && standardId === 'summary' && (
            <AssessmentSummmary
                form={form}
                entry={entry}
                setEntry={setEntry}
            />
        )}

        <div className="max-w-4xl">
            {isError && (
                <Alert status='error' style={{width: 'auto', display: 'inline-flex'}}>
                    <AlertIcon />
                    {isError === true ? 'There was an error submitting the form.' : isError}
                </Alert>
            )}
            <form
                onSubmit={async (event) => {
                    event.preventDefault();

/*
                    // console.log(formSchema);
                    var entrySchema = JSON.stringify(object);
                    // console.log(entrySchema);
                    return;
*/


                    // console.log(event);

                    setIsSubmitting(true);
                    setValidationErrors([]);
                    setIncorrectAnswers([]);

                    const rawEntrySchema = new FormData(event.target);

                    var object = {};
                    for(let [key, value] of rawEntrySchema) {
                        // handle files first
                        if(value instanceof File) {
                            try {
                                const b64file = await getBase64(value);

                                // we perform this same logic to prevent the raw File object from being added
                                if(Reflect.has(object, key)) {
                                    if(!Array.isArray(object[key])) {
                                        object[key] = [object[key]];
                                    }

                                    object[key].push(b64file);
                                } else {
                                    object[key] = b64file;
                                }
                                continue;
                            } catch(e) {
                                console.log(e);
                                continue;
                            }
                        }

                        // handle non-array values / initial values
                        // Reflect.has in favor of: object.hasOwnProperty(key)
                        if(!Reflect.has(object, key)) {
                            // console.log('NO REFLECT.HAS FOR ', key);
                            object[key] = value;
                            continue;
                        }

                        // force multi types to be an array when adding another value
                        if(!Array.isArray(object[key])) {
                            // console.log('SETTING ARRAY FOR ', key);
                            object[key] = [object[key]];
                        }

                        object[key].push(value);
                    };
                    // console.log(object);
                    var entrySchema = JSON.stringify(object);
                    // console.log(entrySchema);

                    let score = ''; // mysql doesn't allow null for decimal/integer even if the column is nullable; use empty string for default value
                    let passed = '';

                    // check for required values matching
                    let requiredValues = {};
                    let correctAnswers = 0;
                    let newValidationErrors = [];
                    let newIncorrectAnswers = [];
                    for(const field of Object.values(JSON.parse(form.schema).fields)) {
                        // the user hasn't answered the question so they have to do that before submitting (this is primarily useful for checkbox/radio options that don't correctly set the required field)
                        if(field.attrs.hasOwnProperty('required') && field.attrs.required) {
                            if(!object.hasOwnProperty(`f-${field.id}`)) {
                                newValidationErrors.push(`"${field.config.label}" <b>is required</b>`);
                            }
                        }

                        if(field.attrs.hasOwnProperty('correct-answer')) {
                            requiredValues[field.id] = field.attrs['correct-answer'];

                            if(!object.hasOwnProperty(`f-${field.id}`) || object[`f-${field.id}`] != field.attrs['correct-answer']) {
                                newIncorrectAnswers.push(field.config.label);//attrs['correct-answer'];
                            } else {
                                correctAnswers++;
                            }
                        }
                    }

                    if(form.scoring_mode !== null && form.scoring_mode.indexOf('scored') !== -1 && Object.keys(requiredValues).length) {
                        score = correctAnswers / Object.keys(requiredValues).length;
                        score = Math.round(score * 100);
                        passed = score >= form.minimum_score ? 1 : 0;
                    }

                    const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })
                    newValidationErrors.sort(sortAlphaNum);
                    newIncorrectAnswers.sort(sortAlphaNum);

                    // TODO: check if form type is the type to show answers before submitting
                    if(Object.values(newValidationErrors).length) {
                        setValidationErrors(newValidationErrors);
                        setIsSubmitting(false);
                        return;
                    } else if(form.scoring_mode !== null && form.scoring_mode.indexOf('correctable') !== -1 && Object.values(newIncorrectAnswers).length) {
                        setIncorrectAnswers(newIncorrectAnswers);
                        if(form.scoring_mode == 'correctable') {
                            setIsSubmitting(false);
                            return;
                        }
                    }

                    // todo: validate
                    let formEntry = new FormData();
                    formEntry.append('form_id', form.id);
                    formEntry.append('user_id', auth.user.id);
                    formEntry.append('form_schema', form.schema);
                    formEntry.append('entry_schema', entrySchema);
                    formEntry.append('score', score);
                    formEntry.append('passed', passed);

                    // formEntry.append('file', );

                    authenticatedApi.post('form-entries', formEntry).then(response => {
                        if(form.scoring_mode !== 'scored_correctable') {
                            setIsSubmitted(true);
                        } else if(form.scoring_mode === 'scored_correctable' && passed === 1) {
                            setIsSubmitted(`You got ${score}% correct which is passing. Congrats!`);
                        }

                        if(passed === 0) {
                            setIsError(`You got ${score}% correct but you need ${Math.round(form.minimum_score)}% to pass. Please try again.`);
                        } else {
                            setIsError(false);
                        }

                        // setIsSubmitting(false);
                        // console.log(response.data);
                    }).catch(e => {
                        setIsError(true); // todo: get actual error message
                    }).finally(() => {
                        setIsSubmitting(false);
                    });
                }}
            >
                {formSchema !== '' && (
                    <ReactFormeoRenderer formData={formSchema} />
                )}

                {/*
                <Button
                    mt={4}
                    colorScheme='brand'
                    isLoading={isSubmitting}
                    spinner={<BeatLoader size={8} color='white' />}
                    type='submit'
                >
                    Submit
                </Button>
                */}


                {isError && (
                    <Box mt={4}>
                        <Alert status='error' style={{width: 'auto', display: 'inline-flex'}}>
                            <AlertIcon />
                            {isError === true ? 'There was an error submitting the form.' : isError}
                        </Alert>
                    </Box>
                )}
                {Object.values(validationErrors).length > 0 && (
                    <Box mt={4}>
                        <Alert status='error' style={{width: 'auto', display: 'inline-flex'}}>
                            <AlertIcon />
                            <div>
                                <p>Please correct the following issues:</p>
                                <ul className='ml-6 list-disc'>
                                    {Object.values(validationErrors).map((validationError, index) => (
                                        <li key={index} dangerouslySetInnerHTML={{__html: validationError}}></li>
                                    ))}
                                </ul>
                            </div>
                        </Alert>
                    </Box>
                )}
                {Object.values(incorrectAnswers).length > 0 && (
                    <Box mt={4}>
                        <Alert status='error' style={{width: 'auto', display: 'inline-flex'}}>
                            <AlertIcon />
                            <div>
                                <p>The following answers are incorrect:</p>
                                <ul className='ml-6 list-disc'>
                                    {Object.values(incorrectAnswers).map((validationError, index) => (
                                        <li key={index} dangerouslySetInnerHTML={{__html: validationError}}></li>
                                    ))}
                                </ul>
                            </div>
                        </Alert>
                    </Box>
                )}
            </form>
        </div>
    </>);
}
