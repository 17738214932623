import { useAuth } from "../AuthProvider";
import Announcements from "../Components/Announcements";
import Notifications from "../Components/Notifications";
import SupportRequestsWidget from "../Components/SupportRequestsWidget";
import AccreditationCards from "../Components/AccreditationCards";

const getTimeOfDayGreeting = () => {
    var data = [
      [0, 4, "Good Night"],
      [5, 11, "Good Morning"],
      [12, 17, "Good Afternoon"],
      [18, 24, "Good Evening"],
    ];
    let hr = new Date().getHours();
    let greeting;

    for(var i = 0; i < data.length; i++){
        if(hr >= data[i][0] && hr <= data[i][1]) {
          greeting = data[i][2];
        }
    }

    return greeting;
};

const todaysDate = () => {
  const date = new Date();
  return date.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
};

export default function Dashboard() {
    let auth = useAuth();


    const communities = auth.getCommunityList();/*.map(community => {
      community.value = community.id;
      community.label = community.city ? `${community.city}, ${community.state}` : community.state;
      return community;
    });*/
    const currentCommunity = communities.find(community => community.id === auth.currentCommunityId);


    return (<>
      <div className="grid auto-cols-fr grid-cols-12 gap-6">
        <div className="col-span-8">
          <div className="mb-6">
            <p className="text-sm text-green font-bold">{todaysDate()}</p>
            <h1 className="text-gray-500 text-3xl">{`${getTimeOfDayGreeting()}, ${auth.user.name}`}</h1>
          </div>
        </div>
        <div className="col-span-4">
        {/* <p className="text-sm text-gray text-right">{currentCommunity ? `${currentCommunity.name}` : null}</p> */}
        </div>
        <div className="col-span-12 lg:col-span-4">
          <Announcements includeHeader />
        </div>

        <div className="col-span-12 lg:col-span-4">
          <div className="grid gap-6">
            <AccreditationCards />
          </div>
        </div>

        <div className="col-span-12 lg:col-span-4">
          <div className="grid gap-6">
            <Notifications/>

            <SupportRequestsWidget includeHeader/>
          </div>
        </div>
      </div>
    </>);
}
