import React, { useEffect, useState } from 'react';
import Select, {components, createFilter} from 'react-select';
import { useAuth } from "../AuthProvider";
import { useSettings } from "../SettingsProvider";
import PortalLoader from "./PortalLoader";

export default function CommunityPicker(props) {
    const auth = useAuth();
    const settings = useSettings();

    const [isLoading, setIsLoading] = useState(false);
    const [currentCommunity, setCurrentCommunity] = useState(auth.currentCommunity);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            setCurrentCommunity(auth.currentCommunity);
        }, 50);

    }, [auth.currentCommunityId]);


    if(settings.isLoading) {
        return (<>
            <div className="flex flex-col block shadow px-6 py-3">
                <PortalLoader text="Loading..." />
            </div>
        </>);
    }

    const communities = auth.getCommunityList().map(community => {
        community.value = community.id;
        community.label = community.city ? `${community.city}, ${community.state}` : community.state;
        return community;
    });
    //const currentCommunity = communities.find(community => community.id === auth.currentCommunityId);

    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
        stringify: option => `${option.label} ${option.value} ${option.data.name}`,
        trim: true,
    };

    const CustomOption = ({ children, ...props }) => (
        <components.Option {...props}>
            {children}
            <div className='text-xs opacity-80'>{props.data.name}</div>
        </components.Option>
    );

    // TODO: add state grouping if there are multiple states

    return <div className='text-black'>
        <Select
            isLoading={isLoading}
            value={auth.currentCommunity}
            isSearchable={true}
            name="community"
            options={communities}
            placeholder="Choose Community..."
            // isClearable={true}
            onChange={c => {auth.setCurrentCommunityId(c?.id ?? null); window.location.reload()}}
            filterOption={createFilter(filterConfig)}
            components={{ Option: CustomOption }}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: '#00ABCC',
                    primary75: '#0AD7FF',
                    primary50: '#85EBFF',
                    primary25: '#E0FAFF',
                },
            })}
        />
    </div>;
}
