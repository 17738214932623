const convertExamplesToList = (examples) => {
    examples = examples.replace('Independentsector.org', `<a href="https://independentsector.org/resource/value-of-volunteer-time/" target="_blank" class="text-blue-600 hover:text-blue-800 underline">Independentsector.org</a>`);

    const splitExamples = examples.split(/\r?\n/).map(example => example.replace(/[a-z]\.\s+/, '').trim());
    return splitExamples;
};


export const AccreditationContent = ({type = 'content', content}) => {
    if(typeof content === 'undefined' || !content) {
        // return null;
    }

    // if it's already html, apply the missing classes

    // use this to decode the html
    var txt = document.createElement("textarea");
    txt.innerHTML = content;
    content = txt.value;

    // let isHtml = false;
    if(content.match('<p>') || content.match('<ol>') || content.match('<ul>')) {
        // isHtml = true;

        content = content.replaceAll('<p>', '<p class="text-gray-600 text-sm">');
        content = content.replaceAll('<ol>', '<ol class="my-3 max-w-4xl text-gray-600 text-sm list-lower-alpha list-outside ml-[20px]">');
        content = content.replaceAll('<ul>', '<ol class="my-3 max-w-4xl text-gray-600 text-sm list-disc list-outside ml-[20px]">');

        return <div className="my-3 max-w-4xl text-black" dangerouslySetInnerHTML={{__html: content}}></div>;
    }

    if(type !== 'list') {
        content = content.replaceAll(/\n/gi, '<br/><br/>');
        return <div className="max-w-4xl text-black" dangerouslySetInnerHTML={{__html: content }}></div>;
    }

    return (
        <ol className="my-3 max-w-4xl text-gray-600 text-sm list-lower-alpha list-outside ml-[20px]">
            {convertExamplesToList(content).map((item, index) => <li key={index} dangerouslySetInnerHTML={{__html: item}}></li>)}
        </ol>
    );


}
