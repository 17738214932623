import { Breadcrumb, Dropdown, CustomFlowbiteTheme } from "flowbite-react";
import { Link, useLocation, useParams } from "react-router-dom";
import { answerAccessKeys, getSimpleName, injectStaticSections, sortBySequence } from "../helpers";
import {BsCircle, BsCheckCircle, BsCheckCircleFill, BsCircleHalf} from 'react-icons/bs';
import { useEffect, useState } from "react";
import { useAuth } from "../AuthProvider";


const customTheme /*: CustomFlowbiteTheme['breadcrumb']*/ = {
    root: {
        base: "",
        list: "lg:flex items-center",
    },

    /*
    item: {
        base: "group lg:flex items-center",
        chevron: "mx-1 h-4 w-4 text-gray-400 group-first:hidden md:mx-2",
        href: {
            off: "flex items-center text-sm font-medium text-gray-500 dark:text-gray-400",
            on: "flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white",
        },
        icon: "mr-2 h-4 w-4",
    },
    */
};


export const FormBreadcrumbs = (props) => {
    const {
        uuid = null,
        standardId = null,
        keyIndicatorId = null,
        indicatorId = null
    } = useParams();
    const location = useLocation();

    const auth = useAuth();

    const [isOverviewCompleted, setIsOverviewCompleted] = useState(false);

    let inProgress = {
        standards: [],
        keyIndicators: [],
        indicators: [],
    };

    let completed = {
        standards: [],
        keyIndicators: [],
        indicators: [],
    };

    useEffect(() => {
        if(props.form && props.entry && props.entry.entry) {
            setIsOverviewCompleted(props.entry.entry.find(obj => obj.standard_id === 'overview') ? true : false);
        } else {
            setIsOverviewCompleted(false);
        }
        //
    }, [props.entry]);

    /*
    if(!isOverviewCompleted) {
        return null;
    }
    */

    if(typeof props.form?.standards === 'undefined') {
        return null;
    }

    if(props.entry) {
        const accessLevel = auth.getCommunityAccessLevel();

        let completedStandards = [];
        let totalStandards = props.form.standards.length;
        const standards = injectStaticSections(props.form, props.entry);

        for(const standard of standards /*props.form.standards*/) {
            if(['overview', 'baseline-requirements', 'additional-information', 'summary'].indexOf(standard.id) !== -1) {
                if(standard.status == 'completed') {
                    completedStandards.push(standard.id);
                }
                continue;
            }


            let completedKeyIndicators = [];
            let totalKeyIndicators = standard.key_indicators.length;

            for(const keyIndicator of standard.key_indicators) {
                let completedIndicators = [];
                let totalIndicators = keyIndicator.indicators.length;

                for(const indicator of keyIndicator.indicators) {
                    const indicatorAnswer =  props.entry.entry.find(obj => obj.indicator_id === indicator.id);

                    if(indicatorAnswer && indicatorAnswer[answerAccessKeys[accessLevel]] != null) {
                        completedIndicators.push(indicator.id);
                        completed.indicators.push(indicator.id);

                        // inProgress.keyIndicators.push(keyIndicator.id);
                    }
                }

                if(completedIndicators.length === totalIndicators) {
                    completedKeyIndicators.push(keyIndicator.id);
                    completed.keyIndicators.push(keyIndicator.id);
                } else if (completedIndicators.length > 0) {
                    inProgress.keyIndicators.push(keyIndicator.id);
                    inProgress.standards.push(standard.id);
                }
            }

            if(completedKeyIndicators.length === totalKeyIndicators) {
                completedStandards.push(standard.id);
                completed.standards.push(standard.id);
            } else if (completedKeyIndicators.length > 0) {
                inProgress.standards.push(standard.id);
            }
        }

        // Todo: worksheet
        if(props.entry.entry.find(obj => obj.hasOwnProperty('worksheet'))) {
            completed['worksheet'] = true;
        }
    }

    const IconStates = {
        'completed': BsCheckCircleFill,
        'in-progress': BsCircleHalf,
        'default': BsCircle,
    };

    const getIcon = (objectType, objectId) => {
        if(objectType === 'worksheet') {
            if(completed[objectType]) {
                return IconStates['completed'];
            }
            if(inProgress[objectType]) {
                return IconStates['in-progress'];
            }

            return IconStates['default'];
        }

        if(completed[objectType].indexOf(objectId) !== -1) {
            return IconStates['completed'];
        }
        if(inProgress[objectType].indexOf(objectId) !== -1) {
            return IconStates['in-progress'];
        }
        return IconStates['default'];
    };


    // add in the other sections next to the standards
    const standards = injectStaticSections(props.form, props.entry);

    const getStandardBreadcrumbTitle = () => {
        for(const standard of standards) {
            if(
                typeof standard.id === 'string'
                && location.pathname.indexOf(`/${standard.id}`) !== -1
            ) {
                return standard.name;
            }
        }
        /*
        if(location.pathname.indexOf('/worksheet') !== -1) {
            return 'Evaluation';
        }
        */
        if(props.standard) {
            return getSimpleName(props.standard.name);
        }

         return 'Choose Section';
        //  return 'Choose Standard';
    }

    return (
        <Breadcrumb aria-label="Assessment Navigator" className="hidden md:block" theme={customTheme.root}>
        <Breadcrumb.Item>
            <Link to={`/forms/${props.form.uuid}`}>
            <p>
            Assessment
            </p>
            </Link>
        </Breadcrumb.Item>

        {/* Standard */}
        <Breadcrumb.Item>
            <Dropdown inline label={getStandardBreadcrumbTitle()}>
                {/*props.form.standards*/ standards.map((standard, standardIndex) => {
                    let icon;
                    let classNames = [];

                    if(standard.hasOwnProperty('status')) {
                        icon = IconStates[standard.status] ?? IconStates['default'];

                        if(standard.status == 'completed') {
                            classNames.push('text-gray-500 italic');
                        }

                        if(location.pathname.indexOf(`/${standard.id}`) !== -1) {
                            classNames.push('bg-green-100 text-green-800 font-bold');
                        }
                    } else {
                        icon = getIcon('standards', standard.id);
                        classNames = [
                            completed.standards.indexOf(standard.id) !== -1 ? 'text-gray-500 italic' : '',
                            parseInt(standardId) === standard.id ? 'bg-green-100 text-green-800 font-bold' : ''
                        ];
                    }

                    return (
                        <Dropdown.Item
                            key={standardIndex}
                            as={Link}
                            to={`/forms/${props.form.uuid}/${standard.id}`}
                            // icon={completed.standards.indexOf(standard.id) !== -1 ? BsCheckCircleFill : BsCircle}
                            icon={icon}
                            className={classNames.join(' ')}
                        >
                            {standard.name}
                        </Dropdown.Item>
                    )
                })}

                {/*
                <Dropdown.Item
                    as={Link}
                    to={`/forms/${props.form.uuid}/worksheet`}
                    // icon={completed.standards.indexOf(standard.id) !== -1 ? BsCheckCircleFill : BsCircle}
                    icon={getIcon('worksheet', 0)}
                    className={[
                        completed.worksheet ? "text-gray-500 italic" : '',
                        location.pathname.indexOf('/worksheet') !== -1 ? 'bg-green-100 text-green-800 font-bold' : ''
                    ].join(' ')}
                >
                    Evaluation: Community Assessment Worksheet
                </Dropdown.Item>
                */}
            </Dropdown>
        </Breadcrumb.Item>

        {/* Key Indicators */}
        {isOverviewCompleted && props.standard && (
        <Breadcrumb.Item>
            <Dropdown inline label={props.keyIndicator ? <><span className="block md:hidden lg:block xl:hidden">{props.keyIndicator.name.split('. ')[0]}</span>
                        <span className="hidden md:block lg:hidden xl:block">{props.keyIndicator.name}</span></> /*getSimpleName(props.keyIndicator.name)*/ : 'Choose Key Indicator'}>
                {props.standard.key_indicators.sort(sortBySequence).map((keyIndicator, keyIndicatorIndex) => (
                    <Dropdown.Item
                        key={keyIndicatorIndex}
                        as={Link}
                        to={`/forms/${props.form.uuid}/${props.standard.id}/${keyIndicator.id}`}
                        // icon={completed.keyIndicators.indexOf(keyIndicator.id) !== -1 ? BsCheckCircleFill : BsCircle}
                        icon={getIcon('keyIndicators', keyIndicator.id)}
                        className={[
                            completed.keyIndicators.indexOf(keyIndicator.id) !== -1 ? "text-gray-500 italic" : '',
                            parseInt(keyIndicatorId) === keyIndicator.id ? 'bg-green-100 text-green-800 font-bold' : ''
                        ].join(' ')}
                    >
                        {keyIndicator.name}
                    </Dropdown.Item>
                ))}
            </Dropdown>
        </Breadcrumb.Item>
        )}

        {/* Indicator */}
        {props.standard && props.keyIndicator && (
        <Breadcrumb.Item>
            <Dropdown inline label={props.indicator ? getSimpleName(props.indicator.name) : 'Choose Indicator'}>
                {props.keyIndicator.indicators.sort(sortBySequence).map((indicator, indicatorIndex) => (
                    <Dropdown.Item
                        key={indicatorIndex}
                        as={Link}
                        to={`/forms/${props.form.uuid}/${props.standard.id}/${props.keyIndicator.id}/${indicator.id}`}
                        // icon={completed.indicators.indexOf(indicator.id) !== -1 ? BsCheckCircleFill : BsCircle}
                        icon={getIcon('indicators', indicator.id)}
                        className={[
                            completed.indicators.indexOf(indicator.id) !== -1 ? "text-gray-500 italic" : '',
                            parseInt(indicatorId) === indicator.id ? 'bg-green-100 text-green-800 font-bold' : ''
                        ].join(' ')}
                    >
                    {/* React.createElement('svg', {"viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"},) <svg>{faCircleCheck.svgPathData}</svg> */}
                        {indicator.name}
                    </Dropdown.Item>
                ))}
            </Dropdown>
        </Breadcrumb.Item>
        )}
    </Breadcrumb>
    );
}
