import { Link, useNavigate, useParams } from "react-router-dom";
import { answerAccessKeys, areAllIndicatorsComplete, boldifyName, designations, formEntryStatuses, getSimpleName, round, sortBySequence } from "../helpers";
import React, { useEffect, useState } from "react";
import { Accordion, Label, Radio, Select, Textarea } from "flowbite-react";
import { useForm } from "react-hook-form"
import { authenticatedApi } from "../Api";
import toast from "react-hot-toast";
import { ScoringScaleModal } from "./ScoringScaleModal";
import { useAuth } from "../AuthProvider";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Tooltip,
 } from "@chakra-ui/react";
import { ExportButton } from "./ExportButton";
import { format, parse, parseISO } from "date-fns";
import { useSettings } from "../SettingsProvider";

const AssessmentStatusTag = (props) => {
    const classes = ['rounded px-3 py-1 text-sm tracking-light'];

    // let icon;

    if(props.status === 'accepted') {
        classes.push('bg-green-200 text-black');
        //
    } else if (props.status === 'in-review') {
        classes.push('bg-yellow-200 text-black');
        //
    } else {
        classes.push('bg-gray-200 text-black');
    }

    if(!props.status) {
        return null;
    }

    return (
        <div className={classes.join(' ')}>{formEntryStatuses[props.status]}</div>
    );
}

export const AssessmentSummmary = (props) => {
    const auth = useAuth();
    const accessLevel = auth.getCommunityAccessLevel();

    const settings = useSettings();

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [scale, setScale] = useState([]);

    useEffect(() => {
        // console.log(props.form);
      if(!props.form || !props.form.grading_scale) {
        return;
      }

      const sortedScale = props.form.grading_scale.sort((a, b) => parseInt(a.score) - parseInt(b.score));

      setScale(sortedScale);

    }, [props.form]);

    let fomDefaultValues = {};

    if(props.entry && accessLevel !== 'lp') {
        if(props.entry.status) {
            fomDefaultValues.status = props.entry.status;
        }

        if(props.entry.community) {
            fomDefaultValues.community_designation = props.entry.community.designation;
        }
    }

    const { control, register, reset, handleSubmit, formState: { errors, isValid, submitCount } } = useForm({
        mode: 'onTouched',
        defaultValues: fomDefaultValues,//{}
    });

    let answersToShow = ['lp'];
    if(accessLevel !== 'lp') {
        answersToShow.push('cp');
    }
    if(accessLevel === 'msa') {
        answersToShow.push('msa');
    }

    let baselineRequirementAnswers = {};
    // useEffect(() => {
        let defaultValues = {};
        if(props.entry && props.entry.entry) {
            baselineRequirementAnswers = props.entry.entry.find(obj => obj.standard_id === 'baseline-requirements') ?? {};
            // defaultValues = baselineRequirementAnswers;
            // console.log(baselineRequirementAnswers);

            // reset(defaultValues);
        }

    // }, [props.form]);

    const getBaselineVal = (key) => {
        if(!baselineRequirementAnswers.hasOwnProperty(key)) {
            return 'No';
        }

        return baselineRequirementAnswers[key] == 'yes' ? 'Yes' : 'No';
    }

    // Todo: ensure standards are met before showing summary

    const answerFor = (object, responseKey, standardId = 'additional-information') => {
        if(!props.entry) {
            return 'N/A';
        }
        let answer = props.entry.entry.find(row => row.standard_id && row.standard_id === standardId);
        // let responseKey = standardId === 'baseline_requirements' ? `lp_requirement_${object.id}_${objectIndex}`  : `lp_answer_${objectIndex}`;
        if(!answer /*|| !answer[responseKey]*/) {
            return 'N/A';
        }

        let questionResponse = answer[responseKey];

        if(object.type == 'boolean') {
            return questionResponse == 'yes' ? 'Yes' : 'No';
        }

        if(object.type == 'file') {
            let files = [];

            return <>
            {/* {props.entry.media.filter(media => media.collection_name == responseKey).length > 0 && ( */}
                <table className="table-auto w-full rounded overflow-hidden bg-white mt-6">
                    <tbody>
                    {props.entry.media.filter(media => media.collection_name == responseKey).map((media, mediaIndex) => (
                        <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-3 py-1.5 ${mediaIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`} key={mediaIndex}>
                            <td className="px-3 py-1.5"><a href={media.original_url} target="_blank">{media.file_name}</a></td>
                            {/* <td className="px-3 py-1.5 text-right"><ConfirmDeleteFile media={media} onDelete={onDeleteFile} /></td> */}
                        </tr>
                    ))}
                    </tbody>
                </table>
            {/* )} */}
            </>;
        }

        if(Array.isArray(questionResponse)) {
            questionResponse = questionResponse.join('<br/>');
        }

        if(object.type == 'date') {
            try {
                // questionResponse = parseISO(questionResponse);
                questionResponse = format(parseISO(questionResponse), 'MM/dd/yy');
            } catch(e) {}
        }

        if(!answer[responseKey]) {
            return 'N/A';
        }

        return <div dangerouslySetInnerHTML={{__html: questionResponse}}></div>;
    }

    const scoreFor = (type, object) => {
        let wrapAnswers = (answer, notes = {}) => {
            for(const answerKey in answer) {
                let answerVal = answer[answerKey] > 0 ? answer[answerKey] : 'N/A';
                // let classes = 'leading-[1.65rem] h-6 w-6 inline-block aspect-square rounded-full';
                let classes = 'px-2 py-1 inline-block rounded';
                if(notes[answerKey]) {
                    answer[answerKey] = <Tooltip label={notes[answerKey]}><span className={`${classes} bg-gray-200`}>{answerVal}</span></Tooltip>;
                } else {
                    answer[answerKey] = <span className={`${classes}`}>{answerVal}</span>;
                }
            }
            return answer;
        };

        let returnAnswer = {
            lp: 0,
            cp: 0,
            msa: 0,
        };

        if(!props.entry) {
            return wrapAnswers(returnAnswer);
        }

        let notes = {
            lp: null,
            cp: null,
            msa: null,
        };
        let indicatorIds = [];

        if(type === 'indicator') {
            indicatorIds.push(object.id);
            // let answer = entry.find(row => row.indicator_id && row.indicator_id === object.id);
            // returnAnswer.lp = answer.lp_answer;
        } else if(type === 'keyIndicator') {
            indicatorIds = object.indicators.map(i => i.id);
        } else if(type === 'standard') {
            object.key_indicators.map(kI => {
                indicatorIds.push(...kI.indicators.map(i => i.id));
            })
        } /*else if(type === 'baseline-requirement') {
            let answer = entry.find(row => row.standard_id && row.standard_id === 'baseline-requirements');

            returnAnswer = {
                lp: answer ? (answer[`lp_requirement_${object.id}`] == 'yes' ? 'Yes' : 'No') : 'N/A',
                cp: answer ? (answer[`cp_requirement_${object.id}`] == 'yes' ? 'Yes' : 'No') : 'N/A',
                msa: answer ? (answer[`msa_requirement_${object.id}`] == 'yes' ? 'Yes' : 'No') : 'N/A',
            };

            return returnAnswer;
        }*/

        let lpTotal = 0;
        let cpTotal = 0;
        let msaTotal = 0;
        for(const indicatorId of indicatorIds) {
            let answer = props.entry.entry.find(row => row.indicator_id && row.indicator_id === indicatorId);
            if(!answer) {
                continue;
            }

            if(type === 'indicator') {
                notes = {
                    lp: answer.lp_notes,
                    cp: answer.cp_notes,
                    msa: answer.msa_notes,
                };
            }

            if(answer.lp_answer !== null) {
                lpTotal++;
                returnAnswer.lp += answer.lp_answer;
            }

            if(answer.cp_answer !== null) {
                cpTotal++;
                returnAnswer.cp += answer.cp_answer;
            }

            if(answer.msa_answer !== null) {
                msaTotal++;
                returnAnswer.msa += answer.msa_answer;
            }
        }

        returnAnswer.lp = lpTotal ? round(returnAnswer.lp / lpTotal) : 0;
        returnAnswer.cp = cpTotal ? round(returnAnswer.cp / cpTotal) : 0;
        returnAnswer.msa = msaTotal ? round(returnAnswer.msa / msaTotal) : 0;

        if(type === 'indicator') {
            returnAnswer = wrapAnswers(returnAnswer, notes);
            /*
            // console.log(notes);
            if(returnAnswer.lp > 0 && notes.lp) {
                returnAnswer.lp = <Tooltip label={notes.lp}>{returnAnswer.lp}</Tooltip>
            }
            if(returnAnswer.cp > 0 && notes.cp) {
                returnAnswer.cp = <Tooltip label={notes.cp}><span className="">{returnAnswer.cp}</span></Tooltip>
            }
            if(returnAnswer.msa > 0 && notes.msa) {
                returnAnswer.msa = <Tooltip label={notes.msa}><span className="">{returnAnswer.msa}</span></Tooltip>
            }
            */
        } else {
            returnAnswer = wrapAnswers(returnAnswer);
        }

        return returnAnswer;
    };

    const calculateAverageForStandard = (standard, type) => {
        if(!props.entry || !type) {
            return 0;
        }

        let total = 0;
        let numberOfIndicators = 0;

        // for(const standard of props.form.standards.sort(sortBySequence)) {
            for(const keyIndicator of standard.key_indicators.sort(sortBySequence)) {
                for(const indicator of keyIndicator.indicators.sort(sortBySequence)) {
                    let answer = props.entry.entry.find(obj => obj.indicator_id === indicator.id);

                    /*
                    if(
                        (type === 'lp' && !answer.lp_answer)
                        ||
                        (type === 'cp' && !answer.cp_answer)
                        ||
                        (type === 'msa' && !answer.msa_answer)
                    ) {
                        return 0;
                    }
                    */

                    if(!answer || !answer[answerAccessKeys[type]]) {
                        return 0;
                    }

                    total += answer[answerAccessKeys[type]];
                    numberOfIndicators++;
                }
            }
        // }

        return round(total/numberOfIndicators);
    }

    const onSubmit = (data) => {
        onClose();
        setIsLoading(true);

        let _answers = props.entry ? props.entry.entry : [];

        let newStatus;
        if(data.hasOwnProperty('status')) {
            newStatus = data.status;
        } else if(props.entry.status === 'draft') {
            newStatus = 'in-review';
        }

        let formEntry = {
            status: newStatus,//'in-review',
            designation: data.community_designation,
        };

        if(data.hasOwnProperty('community_designation')) {
            // authenticatedApi.put('communities/'+props.entry.community_id, {designation: data.community_designation});
        }

        let saveFn = null;
        if(props.entry) {
            saveFn = authenticatedApi.put('form-entries/'+props.entry.id, formEntry);
        } else {
            saveFn = authenticatedApi.post('form-entries', formEntry);
        }
        saveFn.then(response => {
            let _entry = response.data;
            _entry.entry = typeof _entry.entry === 'string' ? JSON.parse(_entry.entry) : _entry.entry;
            props.setEntry(_entry);
            toast.success('Submitted!');
            // todo: show confetti
        }).finally(() => {
            setIsLoading(false);
        });

        // todo: display error

        return true;
    };

    const ContinueButton = () => {
        //  ignore if accepted
        if(props.entry && props.entry.status === 'accepted') {
            return null;
        }

        // ignore if local view is readonly
        if(props.entry && ['draft', 'needs-attention'].indexOf(props.entry.status) === -1 && auth.getCommunityAccessLevel() === 'lp') {
            return null;
        }

        /*
        const {nextText, nextLink} = getNextLink();

        if(nextLink === '') {
            return null;
        }
        */

        let buttonClasses = [];
        if(!isValid) {
            buttonClasses.push('cursor-not-allowed opacity-75 !bg-gray-500');
        } else if(isLoading) {
            buttonClasses.push('cursor-wait');
            buttonClasses.push('opacity-75');
        } else {
            buttonClasses.push('cursor-pointer');
        }

        return (<>
            <button
                type="button"
                onClick={onOpen}
                className={`self-start inline-flex items-center mt-0 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 ${buttonClasses.join(' ')}`}
            >
                Submit
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
            {isLoading && <span className="inline-block ml-3 italic text-sm text-gray-400">Saving...</span>}
        </>);
    }

    return (<>
        <form /* onSubmit={handleSubmit(onSubmit)} */>
        <div className="md:flex gap-6 justify-between">
            <div className="md:flex gap-6 items-center">
                <h1 className="text-3xl font-semibold">Community Assessment</h1>
                <AssessmentStatusTag status={props.entry?.status}/>
            </div>

            <ExportButton form={props.form} entry={props.entry}/>
        </div>

        {/* <div><ExportButton form={props.form} entry={props.entry}/></div> */}

        <div className="my-3 max-w-4xl text-black">
            {settings.isCurrentForm(props.entry?.form_id) && (
                (['draft', 'needs-attention'].indexOf(props.entry?.status) !== -1 && auth.getCommunityAccessLevel() === 'lp')
                ||
                (['accepted'].indexOf(props.entry?.status) === -1 && auth.getCommunityAccessLevel() !== 'lp')
            ) && (<span>Please review all information below before submitting.</span>)}
        </div>

        <Accordion className="my-6">
            <Accordion.Panel>
                <Accordion.Title className="bg-white text-black">Baseline Requirements</Accordion.Title>
                <Accordion.Content className="bg-white p-0">
                    <table className="w-full table-auto bg-white rounded shadow">
                        <thead>
                            <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-6 py-3`}>
                                <th className="px-6 pt-3 pb-1.5 text-left">{/* Baseline Requirements */}</th>
                                <th className="px-6 pt-3 pb-1.5 text-center"><Tooltip label="Local Program"><span>LP</span></Tooltip></th>
                                {/* {auth.getCommunityAccessLevel() !== 'lp' && (<th className="px-6 pt-3 pb-1.5 text-center"><Tooltip label="Coordinating Program"><span>CP</span></Tooltip></th>)} */}
                                {/* {auth.getCommunityAccessLevel() === 'msa' && (<th className="px-6 pt-3 pb-1.5 text-center"><Tooltip label="Main Street America"><span>MSA</span></Tooltip></th>)} */}
                            </tr>
                        </thead>
                        <tbody>
                        {props.form.baseline_requirements.sort(sortBySequence).map((requirement, index) => {
                            return (
                                <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-6 py-3 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`} key={index}>
                                    <td className="px-6 py-3">
                                        <div dangerouslySetInnerHTML={{__html: `${requirement.name}. ${requirement.description.replaceAll('<p>', '<p style="display:inline">')}` }}></div>

                                        {Array.isArray(requirement.additional_schema) && requirement.additional_schema.length > 0 && (<>
                                            {requirement.additional_schema.map((question, questionIndex) => {
                                                return (
                                                    <div className={`text-sm font-semibold tracking-tight text-gray-600 mt-3`} key={questionIndex}>
                                                        <div style={{/*fontWeight: 'bold',*/ fontStyle: 'italic'}} dangerouslySetInnerHTML={{__html: question.question}}></div>
                                                        {answerFor(question, `lp_requirement_${requirement.id}_answer_${question.id}`, 'baseline-requirements')}
                                                    </div>
                                                );
                                            })}
                                        </>)}
                                    </td>
                                    <td className="px-6 py-3 text-center">{getBaselineVal(`lp_requirement_${requirement.id}`)}</td>
                                    {/* {auth.getCommunityAccessLevel() !== 'lp' && (<td className="px-6 py-3 text-center">{getBaselineVal(`cp_requirement_${index+1}`)}</td>)} */}
                                    {/* {auth.getCommunityAccessLevel() === 'msa' && (<td className="px-6 py-3 text-center">{getBaselineVal(`msa_requirement_${index+1}`)}</td>)} */}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
                <Accordion.Title className="bg-white text-black">Cumulative Average Scores</Accordion.Title>
                <Accordion.Content className="bg-white p-0">
                    <div className="m-6">
                    <p className="mb-3 text-sm font-normal">Each number represents the following:</p>
                    <div className="relative overflow-x-auto">
                    <table className="w-full table-auto">
                        <thead>
                            <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-6 py-3`}>
                                {scale.map((score, index) => <th key={index} className={`px-6 py-3 text-center ${index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'}`}><Tooltip label={score.description}><div>{score.score}</div></Tooltip></th>)}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-6 py-3 bg-white`}>
                                {scale.map((score, index) => <td key={index} className={`px-6 py-1.5 text-center ${index % 2 === 0 ? '' : 'bg-gray-100'}`}><Tooltip label={score.description}><div>{score.name}</div></Tooltip></td>)}
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <p className="my-3 text-sm font-semibold">Communities must meet the <Link to={`/forms/${props.form.uuid}/baseline-requirements`} target="_blank" className="text-blue-600 hover:text-blue-800 underline">Baseline Requirements</Link> and average at least three (3) points per Standard to achieve Accreditation. Some Indicators will require documentation. For more information on the scoring scale, <Link to={`/forms/${props.form.uuid}/overview`} target="_blank" className="text-blue-600 hover:text-blue-800 underline">click here</Link>.</p>
                    </div>

                    <div className="text-sm font-semibold text-center p-4">
                        <span className={`px-2 py-1 mr-2 inline-block rounded bg-gray-200 text-transparent cursor-default select-none`}>&nbsp;&nbsp;</span>
                        Hover over scores with a gray box to view the note
                    </div>
                    <table className="w-full table-auto bg-white rounded shadow">
                        <thead>
                            <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-6 py-3`}>
                                <th className="px-6 pt-3 pb-1.5 text-left">{/* Cumulative Average Scores */}</th>
                                <th className="px-6 pt-3 pb-1.5 text-enter sticky top-0 bg-white"><Tooltip label="Local Program"><span>LP</span></Tooltip></th>
                                <th className="px-6 pt-3 pb-1.5 text-enter sticky top-0 bg-white"><Tooltip label="Coordinating Program"><span>CP</span></Tooltip></th>
                                <th className="px-6 pt-3 pb-1.5 text-enter sticky top-0 bg-white"><Tooltip label="Main Street America"><span>MSA</span></Tooltip></th>
                                {/* {auth.getCommunityAccessLevel() !== 'lp' && (<th className="px-6 pt-3 pb-1.5 text-enter">CP</th>)} */}
                                {/* {auth.getCommunityAccessLevel() === 'msa' && (<th className="px-6 pt-3 pb-1.5 text-enter">MSA</th>)} */}
                            </tr>
                        </thead>
                        <tbody>
                        {props.form.standards.sort(sortBySequence).map((standard, index) => (<React.Fragment key={index}>
                            <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-6 py-3`}>{/* ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} */}
                                <td className="px-6 py-3 sticky top-0 bg-white">{standard.name}</td>
                                <td className="px-6 py-3 text-center">{scoreFor('standard', standard).lp}</td>
                                <td className="px-6 py-3 text-center">{scoreFor('standard', standard).cp}</td>
                                <td className="px-6 py-3 text-center">{scoreFor('standard', standard).msa}</td>
                                {/* {auth.getCommunityAccessLevel() !== 'lp' && (<td className="px-6 py-3 text-center">{calculateAverageForStandard(standard, 'cp')}</td>)} */}
                                {/* {auth.getCommunityAccessLevel() === 'msa' && (<td className="px-6 py-3 text-center">{calculateAverageForStandard(standard, 'msa')}</td>)} */}
                            </tr>
                            {standard.key_indicators.sort(sortBySequence).map((keyIndicator) => (<React.Fragment key={keyIndicator.name}>
                                <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-6 py-3 sticky top-8 bg-white`}>{/* ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} */}
                                    <td className="pl-12 pr-6 py-3">{keyIndicator.name}</td>
                                    <td className="px-6 py-3 text-center">{scoreFor('keyIndicator', keyIndicator).lp}</td>
                                    <td className="px-6 py-3 text-center">{scoreFor('keyIndicator', keyIndicator).cp}</td>
                                    <td className="px-6 py-3 text-center">{scoreFor('keyIndicator', keyIndicator).msa}</td>
                                    {/* {auth.getCommunityAccessLevel() !== 'lp' && (<td className="px-6 py-3 text-center">{calculateAverageForStandard(standard, 'cp')}</td>)} */}
                                    {/* {auth.getCommunityAccessLevel() === 'msa' && (<td className="px-6 py-3 text-center">{calculateAverageForStandard(standard, 'msa')}</td>)} */}
                                </tr>
                                {keyIndicator.indicators.sort(sortBySequence).map((indicator) => (<React.Fragment key={indicator.name}>
                                    <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-6 py-3`}>{/* ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} */}
                                    <td className="pl-20 pr-6 py-3">{indicator.name}</td>
                                    <td className="px-6 py-3 text-center">{scoreFor('indicator', indicator).lp}</td>
                                    <td className="px-6 py-3 text-center">{scoreFor('indicator', indicator).cp}</td>
                                    <td className="px-6 py-3 text-center">{scoreFor('indicator', indicator).msa}</td>
                                    {/* {auth.getCommunityAccessLevel() !== 'lp' && (<td className="px-6 py-3 text-center">{calculateAverageForStandard(standard, 'cp')}</td>)} */}
                                    {/* {auth.getCommunityAccessLevel() === 'msa' && (<td className="px-6 py-3 text-center">{calculateAverageForStandard(standard, 'msa')}</td>)} */}
                                </tr>
                            </React.Fragment>))}
                            </React.Fragment>))}
                        </React.Fragment>))}
                        </tbody>
                    </table>
                </Accordion.Content>
            </Accordion.Panel>
            {Array.isArray(props.form.additional_schema) && props.form.additional_schema.length > 0 ? (
                <Accordion.Panel>
                    <Accordion.Title className="bg-white text-black">Additional Information</Accordion.Title>
                    <Accordion.Content className="bg-white p-0">
                    <table className="w-full table-auto bg-white rounded shadow">
                        <tbody>
                        {props.form.additional_schema.map((question, questionIndex) => {
                            return (
                                <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-6 py-3 ${questionIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`} key={questionIndex}>
                                    <td className="px-6 py-3">
                                        <div style={{/*fontWeight: 'bold',*/ fontStyle: 'italic'}} dangerouslySetInnerHTML={{__html: question.question}}></div>
                                        {answerFor(question, `lp_answer_${question.id}`)}
                                    </td>
                                    {/* <td className="px-6 py-3 text-center">{calculateAverageForStandard(standard, 'lp')}</td> */}
                                    {/* {auth.getCommunityAccessLevel() !== 'lp' && (<td className="px-6 py-3 text-center">{calculateAverageForStandard(standard, 'cp')}</td>)} */}
                                    {/* {auth.getCommunityAccessLevel() === 'msa' && (<td className="px-6 py-3 text-center">{calculateAverageForStandard(standard, 'msa')}</td>)} */}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    </Accordion.Content>
                </Accordion.Panel>
            ) : <></>}
        </Accordion>

        {accessLevel !== 'lp' && props.entry?.status !== 'accepted' && settings.isCurrentForm(props.entry?.form_id) && (
            <div className="flex-auto max-w-lg">
                <p className="mb-3 text-md font-semibold tracking-tight text-blue-500">Complete Assessment</p>
                <div className="bg-white shadow px-6 py-6">
                    <div className="flex gap-3 pb-1 justify-between">
                        <Label htmlFor={`status`} value="Status" />
                    </div>

                    <Select id={`status`} {...register(`status`, {required: true})}>
                        <option value="" disabled>Choose...</option>
                        {Object.entries(formEntryStatuses).map(answer => <option value={answer[0]} key={answer[0]}>{answer[1]}</option>)}
                    </Select>

                    <div className="py-3"/>

                    <div className="flex gap-3 pb-1 justify-between">
                        <Label htmlFor={`community_designation`} value="Accreditation Status" />
                    </div>

                    <Select id={`community_designation`} {...register(`community_designation`, {required: true})}>
                        <option value="" disabled>Choose...</option>
                        {Object.entries(designations).map(answer => <option value={answer[0]} key={answer[0]}>{answer[1]}</option>)}
                    </Select>
                </div>
            </div>
        )}

        <div className="py-3"/>

        {settings.isCurrentForm(props.entry?.form_id) && (<>
            <ContinueButton />
        </>)}

        <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Complete Assessment?</ModalHeader>
                <ModalCloseButton />
            <ModalBody>
                <p className="mb-3 text-sm font-normal">
                    {/*(props?.entry?.status === 'draft' || props?.entry?.status === 'needs-attention')*/ accessLevel === 'lp' && (<>Are you sure you are ready to submit this assessment? You will not be able to modify it once submitted.</>)}
                    {/*props?.entry?.status !== 'draft' && props?.entry?.status !== 'needs-attention'*/ accessLevel !== 'lp' && (<>Are you sure you are done reviewing this assessment? If you are marking it as accepted, you will not be able to modify it once submitted.</>)}
                </p>
            </ModalBody>

            <ModalFooter className="flex !justify-between">
                <div onClick={onClose} className="self-start inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-gray-500 rounded shadow cursor-pointer hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300">
                    Cancel
                    {/* <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg> */}
                </div>
                <div onClick={handleSubmit(onSubmit)} className="self-start inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow cursor-pointer hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300">
                    Submit
                    <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </div>
            </ModalFooter>
        </ModalContent>
        </Modal>
        </form>
    </>);
}
