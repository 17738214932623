import { useEffect, useState } from "react";
import {
  useNavigate,
} from "react-router-dom";
import { useAuth } from "../AuthProvider";

export default function Logout() {
    let navigate = useNavigate();
    let auth = useAuth();

    useEffect(() => {
        auth.signOut().then(() => {
            navigate('/', { replace: true });
        });
    });

    return null;
}
