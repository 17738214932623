import { format, parse, parseISO } from "date-fns";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { authenticatedApi } from "../Api";
import PortalLoader from "../Components/PortalLoader";
import { Accordion } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faFilm } from "@fortawesome/pro-duotone-svg-icons";
import { useAuth } from "../AuthProvider";

const SupportRequests = (props) => {
    const auth = useAuth();
    const location = useLocation();
    const [supportRequests, setSupportRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        authenticatedApi.get('/support-requests/mine').then(response => {
            setSupportRequests(response.data.data);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [/*location*/]);

    const header = <>
        {props.includeHeader && (
            <p className="mb-6 text-md font-semibold tracking-tight text-blue-500 bg-white shadow px-6 py-3 has-line">Support Requests</p>
        )}
    </>;

    if(isLoading) {
        return (<>
            <div className="flex flex-col block shadow px-6 py-3">
                <PortalLoader text="Loading..." />
            </div>
        </>);
    }

    let lpVideos = [
        ['Completing the Assessment', 'https://www.youtube.com/watch?v=if5vsUHOwAw&list=PLN0bDNWGJJuNKnCILMi5xSkBu1wQFMvVl&index=2&pp=iAQB'],
        ['Support Tickets', 'https://www.youtube.com/watch?v=zIBCMXM8zvA&list=PLN0bDNWGJJuNKnCILMi5xSkBu1wQFMvVl&index=3&pp=iAQB'],
        ['Account Settings', 'https://www.youtube.com/watch?v=s-RBa0Zzy9c&list=PLN0bDNWGJJuNKnCILMi5xSkBu1wQFMvVl&index=1&pp=iAQB'],
    ];

    let cpVideos = [
        ['Dashboard Overview', 'https://www.youtube.com/watch?v=DJS3TDW8dD4&list=PLN0bDNWGJJuNcutZCSFLR154rjgnxgWqk&index=3&pp=iAQB'],
        ['Communities', 'https://www.youtube.com/watch?v=a-ZIxebsfRY&list=PLN0bDNWGJJuNcutZCSFLR154rjgnxgWqk&index=2&pp=iAQB'],
        ['Forms', 'https://www.youtube.com/watch?v=PZxQYrCy2ww&list=PLN0bDNWGJJuNcutZCSFLR154rjgnxgWqk&index=5&pp=iAQB'],
        ['Form Entries', 'https://www.youtube.com/watch?v=gl5si0PUj-g&list=PLN0bDNWGJJuNcutZCSFLR154rjgnxgWqk&index=4&pp=iAQB'],
        ['Users', 'https://www.youtube.com/watch?v=fgEbqLksXaU&list=PLN0bDNWGJJuNcutZCSFLR154rjgnxgWqk&index=8&pp=iAQB'],
        ['Support Requests', 'https://www.youtube.com/watch?v=cGE5Tk3RUAk&list=PLN0bDNWGJJuNcutZCSFLR154rjgnxgWqk&index=7&pp=iAQB'],
        ['Reports', 'https://www.youtube.com/watch?v=Bi6NKOBF4vg&list=PLN0bDNWGJJuNcutZCSFLR154rjgnxgWqk&index=6&pp=iAQB'],
        ['Audit Log', 'https://www.youtube.com/watch?v=zqRFHpWKtMo&list=PLN0bDNWGJJuNcutZCSFLR154rjgnxgWqk&index=1&pp=iAQB'],
    ];

    let msaVideos = [
        ['Announcements', 'https://www.youtube.com/watch?v=X4uBs_vHCcY&list=PLN0bDNWGJJuNqb4YbpBcECHqWZT3LQ_X1&index=1&pp=iAQB'],
        ['Communities', 'https://www.youtube.com/watch?v=7l6TzzwSoiI&list=PLN0bDNWGJJuNqb4YbpBcECHqWZT3LQ_X1&index=11&pp=iAQB'],
        ['Coordinating Programs', 'https://www.youtube.com/watch?v=mv40nybvF6U&list=PLN0bDNWGJJuNqb4YbpBcECHqWZT3LQ_X1&index=3&pp=iAQB'],
        ['Forms', 'https://www.youtube.com/watch?v=cfuado1RUl4&list=PLN0bDNWGJJuNqb4YbpBcECHqWZT3LQ_X1&index=6&pp=iAQB'],
        ['CP Form Overrides', 'https://www.youtube.com/watch?v=q_PfZcNAWSY&list=PLN0bDNWGJJuNqb4YbpBcECHqWZT3LQ_X1&index=4&pp=iAQB'],
        ['Form Entries', 'https://www.youtube.com/watch?v=akKaciaVdOU&list=PLN0bDNWGJJuNqb4YbpBcECHqWZT3LQ_X1&index=5&pp=iAQB'],
        ['Users', 'https://www.youtube.com/watch?v=s1bKfPx_AZs&list=PLN0bDNWGJJuNqb4YbpBcECHqWZT3LQ_X1&index=10&pp=iAQB'],
        ['Support Requests', 'https://www.youtube.com/watch?v=IjqOefglkRc&list=PLN0bDNWGJJuNqb4YbpBcECHqWZT3LQ_X1&index=9&pp=iAQB'],
        ['Reports', 'https://www.youtube.com/watch?v=g-KVLdykwkg&list=PLN0bDNWGJJuNqb4YbpBcECHqWZT3LQ_X1&index=7&pp=iAQB'],
        ['Audit Log', 'https://www.youtube.com/watch?v=iCtSrGuBgjI&list=PLN0bDNWGJJuNqb4YbpBcECHqWZT3LQ_X1&index=2&pp=iAQB'],
        ['Settings', 'https://www.youtube.com/watch?v=FobQVJptymc&list=PLN0bDNWGJJuNqb4YbpBcECHqWZT3LQ_X1&index=8&pp=iAQB'],
    ];

    return (<>


        <div className="flex flex-col-reverse md:flex-row gap-6">
            <div className="basis-2/3">
        <div className="mb-6 flex flex-col lg:flex-row gap-6">
            <h1 className="text-green text-3xl flex-auto">Support Requests</h1>
            <div className="flex-none">
                <Link to={'/support-requests/new'} className="self-start inline-flex items-center mt-0 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300">
                    Create New Ticket
                    <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </Link>
            </div>
        </div>

                <div className="tracking-tight text-gray-600 bg-white shadow max-w-4xl">
                    {supportRequests.length === 0 && <>
                        <p className="p-6 mb-3 text-sm font-normal">
                            <em>You do not have any open tickets at this time.</em>
                        </p>
                    </>}
                    {supportRequests.map((request, index) => {
                        const latestMessage = request.messages[request.messages.length-1];

                        const isSolved = request.status === 'closed';

                        if(!latestMessage) {
                            return null;
                        }

                            {/* <div className={`text-md tracking-tight px-6 py-3 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`} key={index}> */}
                        return (
                            <Link to={'/support-requests/'+request.uuid} className={`group block text-md tracking-tight px-6 py-3 ${index % 2 === 0 ? 'bg-gray-100 hover:bg-gray-200' : 'bg-white hover:bg-gray-200'} ${isSolved ? 'opacity-50' : ''}`} key={index}>
                                <div className="flex justify-between gap-1">
                                    <p className="text-md font-semibold tracking-tight text-green-500">{request.subject}</p>
                                    <p className="text-md font-semibold tracking-tight text-green-500">{request.status}</p>
                                </div>
                                <p className="text-sm font-semibold tracking-tight text-green-500"><em className="text-gray-500">{latestMessage.user.name} wrote on {format(parseISO(latestMessage.created_at), 'MM/dd/yy')}</em></p>
                                <p className="mb-3 text-sm font-normal tracking-tight" dangerouslySetInnerHTML={{__html: latestMessage.message}}></p>
                            </Link>
                        );
                            {/* </div> */}
                    })}

                </div>
            </div>

            <div className="basis-1/3">
                <div className="mb-6">
                    <h1 className="text-green text-3xl">Documentation</h1>
                </div>
                    <Accordion>
                        <Accordion.Panel>
                            <Accordion.Title>Local Program</Accordion.Title>
                            <Accordion.Content>
                                <ul>
                                    <li className="mb-2"><a className="text-blue-500 hover:text-blue-800" href="/documents/MSA-Accreditation-LP-Manual-v1.pdf" target="_blank"><FontAwesomeIcon icon={faBook}/> User Manual</a></li>
                                    {lpVideos.map((vid, index) => (
                                        <li key={index} className="mb-2"><a className="text-blue-500 hover:text-blue-800" href={vid[1]} target="_blank"><FontAwesomeIcon icon={faFilm}/> {vid[0]}</a></li>
                                    ))}
                                </ul>
                            </Accordion.Content>
                        </Accordion.Panel>

                        {auth.user.coordinating_programs.length > 0 ? (
                            <Accordion.Panel>
                                <Accordion.Title>Coordinating Program</Accordion.Title>
                                <Accordion.Content>
                                    <ul>
                                        <li className="mb-2"><a className="text-blue-500 hover:text-blue-800" href="/documents/MSA-Accreditation-CP-Manual-v1.pdf" target="_blank"><FontAwesomeIcon icon={faBook}/> User Manual</a></li>
                                        {cpVideos.map((vid, index) => (
                                            <li key={index} className="mb-2"><a className="text-blue-500 hover:text-blue-800" href={vid[1]} target="_blank"><FontAwesomeIcon icon={faFilm}/> {vid[0]}</a></li>
                                        ))}
                                    </ul>
                                </Accordion.Content>
                            </Accordion.Panel>
                        ) : <></>}

                        {auth.user.is_msa > 0 ? (
                            <Accordion.Panel>
                                <Accordion.Title>Main Street America</Accordion.Title>
                                <Accordion.Content>
                                    <ul>
                                    <li className="mb-2"><a className="text-blue-500 hover:text-blue-800" href="/documents/MSA-Accreditation-MSA-Manual-v1.pdf" target="_blank"><FontAwesomeIcon icon={faBook}/> User Manual</a></li>
                                        {msaVideos.map((vid, index) => (
                                            <li key={index} className="mb-2"><a className="text-blue-500 hover:text-blue-800" href={vid[1]} target="_blank"><FontAwesomeIcon icon={faFilm}/> {vid[0]}</a></li>
                                        ))}
                                    </ul>
                                </Accordion.Content>
                            </Accordion.Panel>
                        ) : <></>}
                    </Accordion>
            </div>
        </div>

    </>);
};

export default SupportRequests;

