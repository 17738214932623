import { Link } from "react-router-dom";
import { answerAccessKeys, boldifyName, getLinkForFirstUnfilledItem, injectStaticSections, round } from "../helpers";
import { useEffect, useState } from "react";
import { useAuth } from "../AuthProvider";

export const FormStandards = (props) => {
    const auth = useAuth();
    const accessLevel = auth.getCommunityAccessLevel();

    const standards = injectStaticSections(props.form, props.entry);

    const {nextText, nextLink} = getLinkForFirstUnfilledItem(accessLevel, props.form, props.entry);

    return (<>
        {/* <div className="max-w-4xl bg-white rounded shadow p-6 mb-6" dangerouslySetInnerHTML={{__html: props.form.description.replaceAll(/\n/gi, '<br/><br/>') }}></div> */}
        <div>
            {/*props.form.standards*/ standards.map((standard, standardIndex) => {
                let progress = 0;
                let isComplete = false;
                let completed = 0;
                let total = 0;//props.form.indicators.length;

                if(standard.hasOwnProperty('key_indicators')) {
                    for(const keyIndicator of standard.key_indicators) {
                        for(const indicator of keyIndicator.indicators) {
                            total++;

                            if(props.entry) {
                                const indicatorAnswer = props.entry.entry.find(obj => obj.indicator_id === indicator.id);
                                if(indicatorAnswer && indicatorAnswer[answerAccessKeys[accessLevel]] != null) {
                                    completed++;
                                }
                            }
                        }
                    }

                    progress = total === 0 ? 0 : round((completed / total) * 100);
                    isComplete = progress === 100;
                } else if(standard.hasOwnProperty('progress')) {
                    progress = standard.progress;
                    isComplete = standard.status === 'completed';
                }

                // ${standardIndex %2 == 0 ? '' : ''}

                return (
                    <Link
                        key={standardIndex}
                        to={`/forms/${props.form.uuid}/${standard.id}`}
                        className={`px-6 py-3 mb-3 gap-3 flex flex-col sm:flex-row md:flex-col lg:flex-row rounded shadow bg-white ${progress === 100 ? 'text-gray-700' : ''}`}
                    >
                        <span className="flex-auto" dangerouslySetInnerHTML={{__html: boldifyName(standard.name, true) }}></span>
                        <span className={`flex-initial ${progress === 100 ? 'italic' : ''}`}>{progress === 100 ? 'Complete' : `${progress}%`}</span>
                    </Link>
                );
            })}
        </div>

        <Link
            to={nextLink}
            className={`self-start inline-flex items-center mt-0 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300`}
        >
            {nextText}
            <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </Link>

    </>);
}
