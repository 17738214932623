import {
  Alert,
  Button,
} from '@chakra-ui/react'
import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { unauthenticatedApi } from '../Api';
import { useAuth } from "../AuthProvider";
import PortalLoader from '../Components/PortalLoader';
import { Copyright } from "../Components/Copyright";

import logo from '../logo.svg';

export default function SetNewPassword() {
    const { email = false, token = false } = useParams();
    const [isTokenValid, setIsTokenValid] = useState(null);

    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formError, setFormError] = useState('');
    const [formMessage, setFormMessage] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    let from = location.state?.from?.pathname || "/";

    useEffect(() => {
      if(token === false) {
        setIsTokenValid('You must specify a token to reset your password.');
      }

      if(isTokenValid === null) {
        unauthenticatedApi.get(`/password/reset/${email}/${token}`).then(() => {
          setIsTokenValid(true);
        }).catch(e => {
          setIsTokenValid(e.response.data.message ?? 'An unknown error occurred while verifying the reset link.');
        })

      }
    })

    function handleSubmit(event) {
      event.preventDefault();

      setFormError('');
      setFormMessage('');
      setFormSubmitting(true);

      unauthenticatedApi.post('/password/reset', {
        token,
        email,
        password,
        password_confirmation: confirmPassword,
      }).then(() => {
        setFormMessage('Your password has been reset.');
        //
      }).catch(e => {
        setFormError(e.response.data.message ?? 'An unknown error occurred while resetting your password.');
      }).finally(() => {
        setFormSubmitting(false);
      });
    }

    return (
      <div className="max-w-xl mx-auto mt-12">
        <img src={logo} className="App-logo mx-auto max-w-[50%]" alt="logo" />
        <h1 className="mt-12 text-green text-3xl text-center">Set New Password</h1>

        <div className="box rounded-lg shadow-lg my-6 p-6 bg-white">
          {isTokenValid === null && (<PortalLoader text={'Verifying...'} />)}

          {typeof isTokenValid === 'string' && (<>
            <Alert status='error' className='rounded-md mb-6' variant='top-accent'>{isTokenValid}</Alert>
            <Button
                colorScheme='brand'
                width="100%"
                as={Link}
                to={'/forgot-password'}
              >
                Get New Link
              </Button>
          </>)}

          {formMessage !== '' && (<>
            <Alert status='info' className='rounded-md mb-6' variant='top-accent'>{formMessage}</Alert>
            <Button
                colorScheme='brand'
                width="100%"
                as={Link}
                to={'/login'}
              >
                Log In
              </Button>
          </>)}

          {formMessage === '' && isTokenValid === true && (<>
            {formSubmitting && (<PortalLoader text={'Sending...'} />)}
            {!formSubmitting && (<>
              <Alert status='info' className='rounded-md mb-6' variant='top-accent'>
                {/* <AlertIcon /> */}
                Your new password must be at least 8 characters long. We recommend using a mixture of letters, numbers, and special characters.
              </Alert>
            <form onSubmit={handleSubmit}>
              <label className="block mb-6">
                <span className="block text-sm font-medium text-slate-700">New Password</span>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  type="password"
                  required
                  disabled={formSubmitting}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                  "
                      // focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                      // invalid:border-pink-500 invalid:text-pink-600
                      // focus:invalid:border-pink-500 focus:invalid:ring-pink-500
                />
              </label>
              <label className="block mb-6">
                <span className="block text-sm font-medium text-slate-700">Repeat Password</span>
                <input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  name="password_confirmation"
                  type="password"
                  required
                  disabled={formSubmitting}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                  "
                      // focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                      // invalid:border-pink-500 invalid:text-pink-600
                      // focus:invalid:border-pink-500 focus:invalid:ring-pink-500
                />
              </label>
              <Button
                disabled={password.length < 8 || password !== confirmPassword}
                isLoading={formSubmitting}
                colorScheme='brand'
                spinner={<BeatLoader size={8} color='white' />}
                type="submit"
                width="100%"
              >
                Update Password
              </Button>
            </form>
            </>)}
          </>)}


        </div>

        <p className="mt-6 text-center text-sm">
            <Link to={'/login'} replace className="text-green">Back to Login</Link>
        </p>

        <Copyright/>
      </div>
    );
  }
