import { Link } from "react-router-dom";
import { answerAccessKeys, boldifyName, round } from "../helpers";
import { useEffect, useState } from "react";
import { useAuth } from "../AuthProvider";
import { AccreditationContent } from "./AccreditationContent";

export const StandardKeyIndicators = (props) => {
    const auth = useAuth();
    const accessLevel = auth.getCommunityAccessLevel();
    const [isOverviewCompleted, setIsOverviewCompleted] = useState(false);

    useEffect(() => {
        if(props.form && props.entry && props.entry.entry) {
            setIsOverviewCompleted(props.entry.entry.find(obj => obj.standard_id === 'overview') ? true : false);
        } else {
            setIsOverviewCompleted(false);
        }
        //
    }, []);


    if(!isOverviewCompleted) {
        return (<>
            <em className="block mb-3">Please review the Overview before proceeding.</em>
            <Link
                to={`/forms/${props.form.uuid}/overview`}
                className={`self-start inline-flex items-center mt-0 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300`}
            >
                Overview
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </Link>
        </>);
    };

    if(!props.standard) {
        return null;
    }

    return (<>
        <h1 className="text-xl md:text-3xl font-semibold">{props.standard.name}</h1>
        {/*  bg-white rounded shadow p-6 mb-6 */}
        {/* <div className="max-w-4xl text-black" dangerouslySetInnerHTML={{__html: props.standard.description.replaceAll(/\n/gi, '<br/><br/>') }}></div> */}
        <AccreditationContent content={props.standard.description} />

        {props.standard.focus_areas && (<>
        <h2 className="mt-6 text-2xl font-semibold">Focus Areas</h2>
        {/*  bg-white rounded shadow p-6 mb-6 */}
        {/* <div className="max-w-4xl text-black" dangerouslySetInnerHTML={{__html: props.standard.focus_areas.replaceAll(/\n/gi, '<br/><br/>') }}></div> */}
        <AccreditationContent content={props.standard.focus_areas} />
        </>)}

        <h2 className="mt-6 text-2xl font-semibold">Key Indicators</h2>
        {/* {props.standard.key_indicators_description && (<> */}
        {/*  bg-white rounded shadow p-6 mb-6 */}
        {/* <div className="max-w-4xl text-black" dangerouslySetInnerHTML={{__html: props.standard.key_indicators_description.replaceAll(/\n/gi, '<br/><br/>') }}></div> */}
        <AccreditationContent content={props.standard.key_indicators_description} />
        {/* </>)} */}

        <div className="mt-6">
            {props.standard.key_indicators.map((keyIndicator, keyIndicatorIndex) => {
                let progress = 0;
                let isComplete = false;
                let completed = 0;
                let total = 0;//props.form.indicators.length;

                for(const indicator of keyIndicator.indicators) {
                    total++;

                    if(props.entry) {
                        const indicatorAnswer = props.entry.entry.find(obj => obj.indicator_id === indicator.id);
                        if(indicatorAnswer && indicatorAnswer[answerAccessKeys[accessLevel]] != null) {
                            completed++;
                        }
                    }
                }

                progress = total === 0 ? 0 : round((completed / total) * 100);
                isComplete = progress === 100;

                // loop through all standard => key indicators => indicators and check if there is an answer
                // if so, it is complete
                // use same loop for determining progress

                // ${standardIndex %2 == 0 ? '' : ''}

                return (
                    <Link
                        key={keyIndicatorIndex}
                        to={`/forms/${props.form.uuid}/${props.standard.id}/${keyIndicator.id}`}
                        className={`px-6 py-3 mb-3 gap-3 flex flex-col sm:flex-row md:flex-col lg:flex-row rounded shadow bg-white ${progress === 100 ? 'text-gray-600' : ''}`}
                    >
                        <span className="flex-auto" dangerouslySetInnerHTML={{__html: boldifyName(keyIndicator.name) }}></span>
                        <span className={`flex-initial ${progress === 100 ? 'italic' : ''}`}>{progress === 100 ? 'Complete' : `${progress}%`}</span>
                    </Link>
                );
            })}
        </div>

        {/*
        <Link to={`/forms/${props.props.form.uuid}/${props.props.form.standards[0].id}`} className="self-start inline-flex items-center mt-0 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300">
            Begin
            <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </Link>
        */}
    </>);
}
