import { useAuth } from "../AuthProvider";
import {
    useNavigate,
  } from "react-router-dom";
import { FileInput, Label, Select, TextInput, Textarea } from "flowbite-react";
import { useForm } from "react-hook-form"
import { authenticatedApi } from "../Api";
import { useState } from "react";
import { checkFileValidity } from "../helpers";

export default function NewSupportRequest() {
    let auth = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const { control, register, reset, handleSubmit, formState: { errors, isValid, submitCount } } = useForm({
        mode: 'onTouched',
        defaultValues: {
          // name: auth.user.name,
          // email: auth.user.email,
        }
    });

    const onFileChange = (type, event) => {
      if(!checkFileValidity(event)) {
        return;
      }
    };

    const onSubmit = (data) => {
      // console.log(data);
      setIsLoading(true);

      let formEntry = {
        subject: data.subject,
        message: data.message,
      };

      authenticatedApi.post('support-requests', formEntry).then(response => {
        // console.log(response.data);
          // auth.setUser(response.data);

          // toast.success('Saved!');

          const onComplete = () => {
            navigate('/support-requests/'+response.data.uuid);;
          }

          if(data?.file && data.file.length) {
            const formData = new FormData();
            formData.append('file', data.file[0]);

            authenticatedApi.post(`support-requests/${response.data.uuid}/${response.data.messages[0].id}/media`, formData).finally(() => {
              onComplete();
            });
          } else {
            onComplete();
          }
      }).finally(() => {
          setIsLoading(false);
      });

      // todo: display error

      return true;
  };

  let buttonClasses = [];
  if(!isValid) {
      buttonClasses.push('cursor-not-allowed opacity-75 !bg-gray-500');
  } else if(isLoading) {
      buttonClasses.push('cursor-wait');
      buttonClasses.push('opacity-75');
  } else {
      buttonClasses.push('cursor-pointer');
  }

    return (<>
      <div className="mb-6">
        <h1 className="text-green text-3xl">New Support Request</h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`px-6 py-6 mb-3 rounded shadow bg-white max-w-3xl`}>
        <div className="flex flex-col gap-6">

          <div className="flex flex-row gap-3">
            <div className="flex-initial basis-1/3">
              <Label htmlFor="subject">Subject<br/>(Required)</Label>
            </div>
            <div className="flex-auto">
              <TextInput
                {...register('subject', {required: true})}
                id="subject"
                required
              />
            </div>
          </div>

          <div className="flex flex-row gap-3">
            <div className="flex-initial basis-1/3">
              <Label htmlFor="message">Message<br/>(Required)</Label>
            </div>
            <div className="flex-auto">
              <Textarea
                className="p-2.5"
                {...register('message', {required: true})}
                id="message"
                required
              />
            </div>
          </div>

          <div className="flex flex-row gap-3">
            <div className="flex-initial basis-1/3">
              <Label htmlFor="file">File / Screenshot<br/>(Optional)</Label>
            </div>
            <div className="flex-auto">
              <FileInput
                {...register('file')}
                id={'file'}
                size={'sm'}
                onChange={e => onFileChange('file', e)}
                helperText={isLoading ? 'Uploading...' : 'Files must be no larger than 50mb'}
                disabled={isLoading}
              />
            </div>
          </div>

          <button
                type="submit"
                // onClick={onClick}
                className={`self-start inline-flex items-center mt-0 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 ${buttonClasses.join(' ')}`}
                disabled={isLoading}
            >
                Submit
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
            {isLoading && <span className="inline-block ml-3 italic text-sm text-gray-400">Saving...</span>}
        </div>
      </div>
      </form>

    </>);
}
