import { format, parse, parseISO } from "date-fns";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { authenticatedApi } from "../Api";
import PortalLoader from "./PortalLoader";

export function SupportRequests2(props) {
    return (<>
        <div className="mb-6 bg-white shadow px-6 py-3">
            <p className="text-md font-semibold tracking-tight text-blue-500">Support Requests</p>
        </div>
    </>);
}


const SupportRequests = (props) => {
    const [supportRequests, setSupportRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const maxToDisplay = 3;
    const isDetailScreen = props.detail ?? false;

    useEffect(() => {
        setIsLoading(true);

        authenticatedApi.get('/support-requests/current').then(response => {
            let data = response.data;

            // limit main supportRequests screen to the max
            if(!isDetailScreen) {
                // data.length = Math.min(data.length, maxToDisplay);
            }

            // if(isDetailScreen) {
            setSupportRequests(data);
            // } else {
                // setSupportRequests(response.data);
                //isDetailScreen
            // }
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const header = <>
        {props.includeHeader && (
            <p className="mb-6 text-md font-semibold tracking-tight text-blue-500 bg-white shadow px-6 py-3 has-line">Support Requests</p>
        )}
    </>;

    if(isLoading) {
        return (<>
            <div className="flex flex-col block shadow px-6 py-3">
                <PortalLoader text="Loading..." />
            </div>
        </>);
    }

    return (<>
    <div className="tracking-tight text-gray-600 bg-white shadow">
        {props.includeHeader && (<p className="px-6 py-3 text-md font-semibold tracking-tight text-blue-500">
            {supportRequests.length > 0 && (<Link to={'/support-requests'}><>Support Requests</></Link>)}
            {supportRequests.length === 0 && (<>Support Requests</>)}
        </p>)}
        {supportRequests.length === 0 && <>
            <p className="px-6 mb-3 text-sm font-normal">
                <em>You do not have any open tickets at this time.</em>
            </p>
        </>}
        {supportRequests.map((request, index) => {
            if(isDetailScreen || index >= maxToDisplay) {
                return null;
            }

            const latestMessage = request.messages[request.messages.length-1];

            if(!latestMessage) {
                return null;
            }

                {/* <div className={`text-md tracking-tight px-6 py-3 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`} key={index}> */}
            return (
                <Link to={'/support-requests/'+request.id}/* uuid */ className={`group block text-md tracking-tight px-6 py-3 ${index % 2 === 0 ? 'bg-gray-100 hover:bg-gray-200' : 'bg-white hover:bg-gray-200'}`} key={index}>
                    <p className="text-md font-semibold tracking-tight text-green-500">{request.subject}</p>
                    <p className="text-sm font-semibold tracking-tight text-green-500"><em className="text-gray-500">{latestMessage.user.name} wrote on {format(parseISO(latestMessage.created_at), 'MM/dd/yy')}</em></p>
                    <p className="text-sm font-normal tracking-tight" dangerouslySetInnerHTML={{__html: latestMessage.message}}></p>
                </Link>
            );
                {/* </div> */}
        })}

        <div className="px-6 mt-3 mb-3">
            <Link to={'/support-requests/new'} className="self-start inline-flex items-center mt-0 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300">
                Get Help
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </Link>
        </div>
    </div>
    </>);
};

export default SupportRequests;

