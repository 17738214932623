import { useState, useContext } from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useAuth } from "../AuthProvider";
import PortalLoader from "../Components/PortalLoader";
import { Copyright } from "../Components/Copyright";
import { BeatLoader } from "react-spinners";
import { Alert, Button } from "@chakra-ui/react";

import logo from '../logo.svg';
import { useSettings } from "../SettingsProvider";

export default function Login() {
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formError, setFormError] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    const settings = useSettings();

    let from = location.state?.from?.pathname || "/";

    function handleSubmit(event) {
      event.preventDefault();

      setFormError('');
      setFormSubmitting(true);

      auth.signIn(email, password).then(() => {
        /*
        if(window.location.host === 'localhost:3000') {
          window.location = 'http://localhost:3000'; // TODO: REMOVE THIS
        } else {
          window.location = '/';
        }
        */

        navigate(from, { replace: true });
        setFormSubmitting(false);
      }).catch((e) => {
        setFormSubmitting(false);
        setFormError(e.response.data.message ?? 'An unknown error occurred while logging in.');
      });
    }

    return (
      <div className="max-w-xl mx-auto">
        <img src={logo} className="App-logo mx-auto max-w-[50%]" alt="logo" />
        <h1 className="mt-12 text-green-500 text-3xl text-center">Main Street Accreditation</h1>

        <div className="box rounded-lg shadow-lg mt-6 p-6 bg-white">
          {formError !== '' && (
            <Alert status='error' className='rounded-md mb-6' variant='top-accent'>{formError}</Alert>
          )}

          {formSubmitting && (<PortalLoader text={'Logging In...'} />)}
          {!formSubmitting && (
          <form onSubmit={handleSubmit}>
            <label className="block mb-6">
              <span className="block text-sm font-medium text-slate-700">Email</span>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                name="email"
                type="text"
                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                "
                    // focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    // invalid:border-pink-500 invalid:text-pink-600
                    // focus:invalid:border-pink-500 focus:invalid:ring-pink-500
              />
            </label>
            <label className="block mb-6">
              <span className="block text-sm font-medium text-slate-700">Password</span>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                name="password"
                type="password"
                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                "
                    // focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    // invalid:border-pink-500 invalid:text-pink-600
                    // focus:invalid:border-pink-500 focus:invalid:ring-pink-500
              />
            </label>
            <Button
              isLoading={formSubmitting}
              colorScheme='brand'
              spinner={<BeatLoader size={8} color='white' />}
              type="submit"
              width="100%"
            >
              Login
            </Button>
          </form>
          )}
        </div>

        {/*
        <p className="mt-6 text-center text-sm">
          {!settings.isLoading && settings.hasOwnProperty('login_help') && (
            <span dangerouslySetInnerHTML={{__html: settings.login_help}}></span>
          )}
        </p>
        */}

        <p className="mt-6 text-center text-sm">
            <Link to={'/forgot-password'} className="text-green">Forgot Password</Link>
        </p>

        <Copyright/>
      </div>
    );
  }
