import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Tooltip,
  } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export function ScoringScaleModal(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [scale, setScale] = useState([]);

    useEffect(() => {
        // console.log(props.form);
      if(!props.form || !props.form.grading_scale) {
        return;
      }

      const sortedScale = props.form.grading_scale.sort((a, b) => parseInt(a.score) - parseInt(b.score));

      setScale(sortedScale);

    }, [props.form]);


    return (
    <>
        <span onClick={onOpen} className="text-xs underline text-gray-600 cursor-pointer">Scoring Scale</span>
        {/* <Button onClick={onOpen}>Open Modal</Button> */}

        <Modal isOpen={isOpen} onClose={onClose} size='5xl'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Scoring Scale</ModalHeader>
                <ModalCloseButton />
            <ModalBody>
                <p className="mb-3 text-sm font-normal">Each number represents the following:</p>
                <div className="relative overflow-x-auto">
                <table className="w-full table-auto">
                    <thead>
                        <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-6 py-3`}>
                            {scale.map((score, index) => <th key={index} className={`px-6 py-3 text-center ${index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'}`}><Tooltip label={score.description}><div>{score.score}</div></Tooltip></th>)}
                            {/*
                            <th className="px-6 py-3 text-center bg-gray-100">1</th>
                            <th className="px-6 py-3 text-center bg-gray-200">2</th>
                            <th className="px-6 py-3 text-center bg-gray-100">3</th>
                            <th className="px-6 py-3 text-center bg-gray-200">4</th>
                            <th className="px-6 py-3 text-center bg-gray-100">5</th>
                            */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-6 py-3 bg-white`}>
                            {scale.map((score, index) => <td key={index} className={`px-6 py-1.5 text-center ${index % 2 === 0 ? '' : 'bg-gray-100'}`}><Tooltip label={score.description}><div>{score.name}</div></Tooltip></td>)}
                            {/*
                            <td className="px-6 py-1.5 text-center">Not being addressed.</td>
                            <td className="px-6 py-1.5 text-center bg-gray-100">Minimal work but needs more effort.</td>
                            <td className="px-6 py-1.5 text-center">Evidence of satisfactory progress.</td>
                            <td className="px-6 py-1.5 text-center bg-gray-100">Has achieved success within this indicator.</td>
                            <td className="px-6 py-1.5 text-center">Outstanding achievement. One that other programs could replicate.</td>
                            */}
                        </tr>
                    </tbody>
                </table>
                </div>
                <p className="my-3 text-sm font-semibold">Communities must meet the <Link to={`/forms/${props.form.uuid}/baseline-requirements`} target="_blank" className="text-blue-600 hover:text-blue-800 underline">Baseline Requirements</Link> and average at least three (3) points per Standard to achieve Accreditation. Some Indicators will require documentation. For more information on the scoring scale, <Link to={`/forms/${props.form.uuid}/overview`} target="_blank" className="text-blue-600 hover:text-blue-800 underline">click here</Link>.</p>
            </ModalBody>

            {/*
            <ModalFooter>
                <div onClick={onClose} className="self-start inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow cursor-pointer hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300">
                    Close
                    {/* <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg> *\/}
                </div>
            </ModalFooter>
            */}
        </ModalContent>
        </Modal>
    </>
    )
}
