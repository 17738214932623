import * as React from "react";
import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
  } from "react-router-dom";
import { authenticatedApi, cookieDomain, currentCommunityIdName, getAccessToken, request, setAccessToken, tokenName, unauthenticatedApi } from "./Api";
import { stateAbbrToName } from "./helpers";
import Cookies from "js-cookie";

export const AuthContext = React.createContext({
    user: null,
    setUser: (user) => {},
    isLoading: true,
    signIn: (callback) => {},
    signOut: (callback) => {},

    getCommunityList: () => {},//
    currentCommunity: null,
    currentCommunityId: localStorage.getItem(currentCommunityIdName),
    setCurrentCommunityId: (id) => {},
    getCommunityAccessLevel: () => {},//
  });

export function useAuth() {
    return React.useContext(AuthContext);
}

export function AuthProvider({ children }) {
    let [user, setUser] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    // handle when we need to adjust the community manually
    let defaultCommunityId = localStorage.getItem(currentCommunityIdName);
    if(window.location.search.indexOf('community=') !== -1) {
      const communityIdMatch = window.location.search.match(/community=(\d+)/);
      if(communityIdMatch.length == 2) {
        defaultCommunityId = parseInt(communityIdMatch[1]);
        localStorage.setItem(currentCommunityIdName, defaultCommunityId); // update it immediately
        let newPath = window.location.pathname + window.location.search.replace(/[\?&]community=\d+/, '');
        // wait to rewrite url to prevent the code from re-running too early
        setTimeout(() => {
          window.history.replaceState({} , null, newPath);
        }, 150);
      }
    }

    const [currentCommunityId, setCurrentCommunityId] = React.useState(defaultCommunityId);
    const [currentCommunity, setCurrentCommunity] = React.useState(null);

    React.useEffect(() => {
      let checkToken = getAccessToken();
      // console.log(user);
      // console.log(checkToken);
      if(isLoading && user == null/* && checkToken != ''*/) {
        authenticatedApi.get('/users/me').then(response => {
          setUser(response.data);

          // console.log(response.data);

          // todo: determine communities vs coordinatingProgramCommunities

          // set community if possible
          let didMatch = false;
          let keysToCheck = ['communities', 'coordinating_program_communities'];
          for(const keyToCheck of keysToCheck) {
            if(response.data[keyToCheck].length) {
              // check if one of the returned communities matches the loaded community
              for(const community of response.data[keyToCheck]) {
                if(community.id === parseInt(currentCommunityId)) {
                  // setCurrentCommunityId(community.id);
                  setCurrentCommunity(community);
                  didMatch = true;
                  break;
                }
              }
            }
          }

          // if null, set it to the first available
          if(!didMatch) {
            setCurrentCommunityId(response.data?.communities[0].id ?? response.data?.coordinating_program_communities[0].id ?? null);
            setCurrentCommunity(response.data?.communities[0] ?? response.data?.coordinating_program_communities[0] ?? null);
          }
          /*
          else {
            setCurrentCommunityId(null);
          }
          */
        }).catch(e => {
          // log user out & redirect back to login screen
        }).finally(() => {
          // setTimeout(() => {
            setIsLoading(false);
          // }, 1000);
        });
      }
    }, [user]);

    React.useEffect(() => {
      // setCurrentCommunityId(localStorage.getItem(currentCommunityIdName));
      localStorage.setItem(currentCommunityIdName, currentCommunityId);

      let newCommunity = getCommunityList().find(community => community.id === parseInt(currentCommunityId));
      setCurrentCommunity(newCommunity);
      // console.log(getCommunityList());
    }, [
      currentCommunityId
      // localStorage
    ]);

    let signIn = (email, password) => {
      return unauthenticatedApi.post('/token/request', {
        email,
        password,
        token_name: 'portal',
      }).then(response => {
        setAccessToken(response.data.access_token);
        setUser(response.data.user);
        // console.log(response.data.user);
      });
    };

    let signOut = () => {
      return new Promise((resolve, reject) => {
        localStorage.removeItem(currentCommunityIdName);
        // localStorage.removeItem(tokenName);
        Cookies.remove(tokenName, {
          domain: cookieDomain,
      });
        authenticatedApi.get('/revoke-token');

        setCurrentCommunity(null);
        setUser(null);

        resolve();
      });
    };

    let getCommunityList = () => {
      if(!user) {
        return [];
      }

      let communityListObj = {};

      user.communities.map(community => {
        community.is_lp = true;
        community.is_cp = false;
        community.is_msa = false;
        communityListObj[community.id] = community;
        // return community;
      });

      user.coordinating_program_communities.map(community => {
        community.is_lp = communityListObj.hasOwnProperty(community.id) ? true : false;
        community.is_cp = true;
        community.is_msa = false;
        communityListObj[community.id] = community;
        // return community;
      });

      let communityList = Object.values(communityListObj);

      /*
      let communityList = [
        ...user.communities.map(community => {
          community.is_lp = true;
          community.is_cp = false;
          community.is_msa = false;
          return community;
        }),
        ...user.coordinating_program_communities.map(community => {
          community.is_lp = false;
          community.is_cp = true;
          community.is_msa = false;
          return community;
        }),
      ];
      */

      // friendlify state, and sort
      communityList = communityList.map(community => {
        community.state = stateAbbrToName(community.state);
        return community;
      }).sort((a, b) => {
        // handle edge case where data is null
        if(a.state === null || a.city === null) {
          return 1;
        }
        return a.state.localeCompare(b.state) || a.city.localeCompare(b.city);
      });

      return communityList;
    };

    // let currentCommunity = getCommunityList().find(community => community.id === currentCommunityId);

    let getCommunityAccessLevel = (findCommunity = null) => {
      if(!findCommunity) {
        findCommunity = currentCommunity;
      }

      if(!findCommunity) {
        return false;
      }

      if(user.is_msa) {
        return 'msa';
      }

      if(user.coordinating_program_communities.find(community => community.id === findCommunity.id)) {
        return 'cp';
      }

      if(user.communities.find(community => community.id === findCommunity.id)) {
        return 'lp';
      }

      return false;
    };

    let value = {
      user,
      setUser,
      isLoading,
      signIn,
      signOut,

      getCommunityList,
      currentCommunity,
      currentCommunityId,
      setCurrentCommunityId,
      getCommunityAccessLevel,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }) {
    let auth = useAuth();
    let location = useLocation();

    if (!auth.user) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}


export function RequireGuest({ children }) {
    let auth = useAuth();
    let location = useLocation();

    let from = location.state?.from?.pathname || "/";

    if (auth.user) {
      return <Navigate to={from} replace />;
    }

    return children;
}

function AuthStatus() {
    let auth = useAuth();
    let navigate = useNavigate();

    if (!auth.user) {
      return <p>You are not logged in.</p>;
    }

    return (
      <p>
        Welcome {auth.user}!{" "}
        <button
          onClick={() => {
            auth.signOut(() => navigate("/"));
          }}
        >
          Sign out
        </button>
      </p>
    );
}
