import { Link } from "react-router-dom";
import { answerAccessKeys, boldifyName, round } from "../helpers";
import { useAuth } from "../AuthProvider";

export const KeyIndicatorIndicators = (props) => {
    const auth = useAuth();
    const accessLevel = auth.getCommunityAccessLevel();

    if(!props.keyIndicator) {
        return null;
    }

    return (<>
        <h1 className="text-xl md:text-3xl font-semibold">{props.keyIndicator.name}</h1>
        {/*  bg-white rounded shadow p-6 mb-6 */}
        {/* <div className="max-w-4xl text-black" dangerouslySetInnerHTML={{__html: props.indicator.description.replaceAll(/\n/gi, '<br/><br/>') }}></div> */}

        {/* <div className="max-w-4xl text-black text-sm" dangerouslySetInnerHTML={{__html: props.standard.examples.replaceAll(/\n/gi, '<br/><br/>') }}></div> */}

        <div className="mt-6">
            {props.keyIndicator.indicators.map((indicator, indicatorIndex) => {
                let progress = 0;
                let isComplete = false;
                let completed = 0;
                let total = 1;//0;//props.form.indicators.length;

                if(props.entry) {
                    const indicatorAnswer = props.entry.entry.find(obj => obj.indicator_id === indicator.id);
                    if(indicatorAnswer && indicatorAnswer[answerAccessKeys[accessLevel]] != null) {
                        completed++;
                    }
                }

                progress = total === 0 ? 0 : ((completed / total) * 100);
                isComplete = progress === 100;

                // loop through all standard => key indicators => indicators and check if there is an answer
                // if so, it is complete
                // use same loop for determining progress

                // ${standardIndex %2 == 0 ? '' : ''}

                return (
                    <Link
                        key={indicatorIndex}
                        to={`/forms/${props.form.uuid}/${props.standard.id}/${props.keyIndicator.id}/${indicator.id}`}
                        className={`px-6 py-3 mb-3 gap-3 flex flex-col sm:flex-row md:flex-col lg:flex-row rounded shadow bg-white ${progress === 100 ? 'text-gray-600' : ''}`}
                    >
                        <span className="flex-auto" dangerouslySetInnerHTML={{__html: boldifyName(indicator.name) }}></span>
                        <span className={`flex-initial ${progress === 100 ? 'italic' : ''}`}>{progress === 100 ? 'Complete' : `${progress}%`}</span>
                    </Link>
                );
            })}
        </div>

        {/*
        <Link to={`/forms/${props.props.form.uuid}/${props.props.form.standards[0].id}`} className="self-start inline-flex items-center mt-0 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300">
            Begin
            <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </Link>
        */}
    </>);
}
