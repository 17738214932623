import { useEffect, useState } from "react";
import { authenticatedApi } from "../Api";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Tooltip,
 } from "@chakra-ui/react";

export default function ConfirmDeleteFile(props) {
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const deleteMedia = () => {
        setIsLoading(true);
        authenticatedApi.delete(`/media/${props.media.id}`).then(response => {
            setIsLoading(false);
            try {
                props.onDelete(response.data.media /*props.media*/);
            } catch(e) {}
        });
    }

    return (<>
        <Tooltip label='Delete File'>
            <div onClick={onOpen} className="self-start inline-flex items-center py-1 px-1 text-xs font-medium text-center text-black hover:text-red-600 bg-gray-200 rounded cursor-pointer hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-400">
                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" /></svg>
            </div>
        </Tooltip>
        <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Confirm</ModalHeader>
                <ModalCloseButton />
            <ModalBody>
                <p className="mb-3 text-sm font-normal">Are you sure you want to delete this file?</p>
            </ModalBody>

            <ModalFooter className="flex !justify-between">
                <div onClick={onClose} className="self-start inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-gray-500 rounded shadow cursor-pointer hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300">
                    Cancel
                    {/* <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg> */}
                </div>
                <div onClick={deleteMedia} className="self-start inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-500 rounded shadow cursor-pointer hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300">
                    Delete
                    <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clip-rule="evenodd" /></svg>
                </div>
            </ModalFooter>
        </ModalContent>
        </Modal>
        </>);

};
