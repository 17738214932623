import axios from "axios";
import Cookies from "js-cookie";

let cookieDomain;
let baseUrl;
let apiUrl;
let adminUrl;

if(window.location.host.indexOf('localhost') !== -1) {
    cookieDomain = 'localhost';
    baseUrl = 'http://localhost:80';
    apiUrl = baseUrl + '/api/';
    adminUrl = 'http://localhost:3001';
} else if(window.location.host.indexOf('bravo.msa-reporting.appadministrate.com') !== -1) {
    cookieDomain = 'bravo.msa-reporting.appadministrate.com';
    baseUrl = 'https://bravo.msa-reporting.appadministrate.com/login';
    apiUrl = 'https://api.bravo.msa-reporting.appadministrate.com';
    adminUrl = 'https://admin.bravo.msa-reporting.appadministrate.com';
} else if(window.location.host.indexOf('msa-reporting.appadministrate.com') !== -1) {
    cookieDomain = 'msa-reporting.appadministrate.com';
    baseUrl = 'https://msa-reporting.appadministrate.com/login';
    apiUrl = 'https://api.msa-reporting.appadministrate.com';
    adminUrl = 'https://admin.msa-reporting.appadministrate.com';
} else {
    cookieDomain = 'mainstreetreporting.org';
    baseUrl = 'https://www.mainstreetreporting.org';
    apiUrl = 'https://api.mainstreetreporting.org';
    adminUrl = 'https://admin.mainstreetreporting.org';
}

const currentCommunityIdName = 'current-community';

//https://stackoverflow.com/a/52171480
const TSH=s=>{for(var i=0,h=9;i<s.length;)h=Math.imul(h^s.charCodeAt(i++),9**9);return h^h>>>9};

const tokenName = 'msa-token-'+Math.abs(TSH(cookieDomain));

let token = '';
const getAccessToken = () => {
    if(token !== '') {
        return token;
    }

    // let checkToken = localStorage.getItem(tokenName);
    let checkToken = Cookies.get(tokenName);
    if(checkToken/* !== null*/) {
        token = checkToken;
    }

    return token;
}

const unauthenticatedApi = axios.create({
    baseURL: apiUrl,
});

const authenticatedApi = axios.create({
    baseURL: apiUrl,
    headers: {
        Authorization: {
            toString() {
                return `Bearer ${getAccessToken()}`
            }
        }
    }
});

const setAccessToken = (accessToken) => {
    // localStorage.setItem(tokenName, accessToken);
    Cookies.set(tokenName, accessToken, {
        domain: cookieDomain,
        expires: 30, // require login every 30 days
    });
    token = accessToken;

    authenticatedApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    return true;
}

let settings = null;
const loadSettings = () => {
    if(settings !== null) return;

    unauthenticatedApi.get(`/settings`).then((response) => {
        // console.log(response);
    });
}

export {
    cookieDomain,
    baseUrl,
    apiUrl,
    adminUrl,

    currentCommunityIdName,
    tokenName,

    getAccessToken,
    setAccessToken,

    unauthenticatedApi,
    authenticatedApi,
};
