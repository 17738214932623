import Announcements from "../Components/Announcements";

export default function AllAnnouncements() {
    return (<>
      <div className="mb-6">
        <h1 className="text-blue text-3xl">Announcements</h1>
      </div>

      <Announcements detail />
    </>);
}
