import { useAuth } from "../AuthProvider";
import {
    useNavigate,
    useParams,
  } from "react-router-dom";
import { FileInput, Label, Select, TextInput, Textarea } from "flowbite-react";
import { useForm } from "react-hook-form"
import { authenticatedApi } from "../Api";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import PortalLoader from "../Components/PortalLoader";
import { format, parseISO } from "date-fns";
import { checkFileValidity } from "../helpers";

export default function ViewSupportRequest() {
    const {
      uuid = null
    } = useParams();
    const navigate = useNavigate();
    let auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [supportRequest, setSupportRequest] = useState(null);

    useEffect(() => {
      setIsLoading(true);

      authenticatedApi.get('/support-requests/'+uuid).then(response => {
          let data = response.data;

          setSupportRequest(data);
      }).finally(() => {
          setIsLoading(false);
      });
    }, []);


    const { control, register, reset, handleSubmit, formState: { errors, isValid, submitCount } } = useForm({
        mode: 'onTouched',
        defaultValues: {
          // name: auth.user.name,
          // email: auth.user.email,
        }
    });

    const onFileChange = (type, event) => {
      if(!checkFileValidity(event)) {
        return;
      }
    };

    const onSubmit = (data) => {
      // console.log(data);
      setIsLoading(true);

      let formEntry = {
        subject: data.subject,
        message: data.message,
      };

      authenticatedApi.post(`support-requests/${uuid}/reply`, formEntry).then(response => {
        // console.log(response.data);
          // auth.setUser(response.data);

          const onComplete = (data) => {
            toast.success('Reply sent!');
            setSupportRequest(data);
            reset();
          }

          if(data?.file && data.file.length) {
            const formData = new FormData();
            formData.append('file', data.file[0]);

            authenticatedApi.post(`support-requests/${response.data.uuid}/${response.data.messages[response.data.messages.length-1].id}/media`, formData).then((finalResponse) => {
              onComplete(finalResponse.data);
            });
          } else {
            onComplete(response.data);
          }
      }).finally(() => {
          setIsLoading(false);
      });

      // todo: display error

      return true;
  };

  const closeTicket = () => {
    setIsLoading(true);
    let formEntry = {
      status: 'closed',
    };
    authenticatedApi.put(`support-requests/${supportRequest.id}`, formEntry).then(response => {
        toast.success('Marked as solved!');
        navigate('/support-requests');
    }).finally(() => {
        setIsLoading(false);
    });
  };

  let buttonClasses = [];
  if(!isValid) {
      buttonClasses.push('cursor-not-allowed opacity-75 !bg-gray-500');
  } else if(isLoading) {
      buttonClasses.push('cursor-wait');
      buttonClasses.push('opacity-75');
  } else {
      buttonClasses.push('cursor-pointer');
  }


  if(isLoading || supportRequest === null) {
    return (<>
        <div className="flex flex-col block shadow px-6 py-3">
            <PortalLoader text="Loading..." />
        </div>
    </>);
}


    return (<>
      <div className="mb-6">
        <h1 className="text-green text-3xl">Support Request: {supportRequest.subject}</h1>
      </div>

      {supportRequest.messages.map((message, index) => {
        {/* const latestMessage = request.messages[request.messages.length-1]; */}

            {/* <div className={`text-md tracking-tight px-6 py-3 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`} key={index}> */}
        return (
            <div className={`group block text-md tracking-tight px-6 py-3 max-w-3xl ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`} key={index}>
                {/* <p className="text-md font-semibold tracking-tight text-green-500">{request.subject}</p> */}
                <p className="text-sm font-semibold tracking-tight text-green-500"><em className="text-gray-500">{message.user.name} wrote on {format(parseISO(message.created_at), 'MM/dd/yy \'at\' hh:mm aaaa')}</em></p>
                <p className="mt-3 text-md font-normal tracking-tight" dangerouslySetInnerHTML={{__html: message.message}}></p>

                {message.media.length > 0 && (<>
                  {/* <p className="mt-6">Files:</p> */}
                  <table className="table-auto w-full rounded overflow-hidden bg-white mt-1 border rounded">
                      <tbody>
                      {message.media.map((media, mediaIndex) => (
                          <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-3 py-1.5 ${mediaIndex % 2 === 0 ? (index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100') : (index % 2 === 0 ? 'bg-white' : 'bg-white')}`} key={mediaIndex}>
                              <td className="px-3 py-1.5"><a href={media.original_url} target="_blank">{media.file_name}</a></td>
                              {/* <td className="px-3 py-1.5 text-right"><ConfirmDeleteFile media={media} onDelete={onDeleteFile} /></td> */}
                          </tr>
                          ))}
                      </tbody>
                  </table>
                </>)}
            </div>
        );
            {/* </div> */}
    })}

      <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`px-6 py-6 mb-3 rounded shadow bg-white max-w-3xl`}>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <div className="flex-auto">
              <Label htmlFor="message">Replying as {auth.user.name}</Label>
              <Textarea
              className="mt-1 p-2.5"
                {...register('message', {required: true})}
                id="message"
                required
              />
            </div>
            <div className="flex-auto">
              <Label htmlFor="file">File/Screenshot (Optional)</Label>
              <FileInput
                {...register('file')}
                id={'file'}
                size={'sm'}
                onChange={e => onFileChange('file', e)}
                helperText={isLoading ? 'Uploading...' : 'Files must be no larger than 50mb'}
                disabled={isLoading}
                className="mt-1"
              />
            </div>
          </div>

          <div className="flex flex-row justify-between gap-3">
            <div className="flex-auto">
              <button
                  type="submit"
                  // onClick={onClick}
                  className={`self-start inline-flex items-center mt-0 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 ${buttonClasses.join(' ')}`}
                  disabled={isLoading}
              >
                  Respond
                  <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </button>
            </div>
            <div className="flex-auto">
              {isLoading && <span className="inline-block ml-3 italic text-sm text-gray-400">Saving...</span>}
            </div>
            <div className="flex-auto text-right">
              <button
                  type="button"
                  onClick={closeTicket}
                  className={`self-start inline-flex items-center mt-0 py-2 px-3 text-sm font-medium text-center text-white bg-gray-500 rounded shadow hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 !cursor-pointer !opacity-100 ${buttonClasses.join(' ')}`}
                  disabled={isLoading}
              >
                  Mark as Solved
                  {/* <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg> */}
              </button>
            </div>
          </div>
        </div>
      </div>
      </form>

    </>);
}
